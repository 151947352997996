
import cn from 'classnames';
import { useStyle } from 'utils/hooks';
import styles from './styles';

export default function Typo(props) {
  const {
    type = 'text',
    className,
    style = {},
    children,
    ...restProps
  } = props;
  const classes = useStyle(styles, props);
  const wrapContent = (content) => {
    const c = cn(
      className,
      [classes.base],
      [classes[`type-${type}`]],
      { [classes.title]: type.includes('title') },
    );

    const typoProps = {
      className: c,
      style,
      ...restProps
    }

    switch (type) {
      case 'title-hero': return <h1 {...typoProps}>{content}</h1>;
      case 'title-1': return <h1 {...typoProps}>{content}</h1>;
      case 'title-2': return <h2 {...typoProps}>{content}</h2>;
      case 'title-3': return <h3 {...typoProps}>{content}</h3>;
      case 'title-4': return <h4 {...typoProps}>{content}</h4>;
      case 'title-5': return <h5 {...typoProps}>{content}</h5>;
      
      case 'text': return <p {...typoProps}>{content}</p>;
      case 'span': return <span {...typoProps}>{content}</span>
    }
    
    return null;
  }

  return wrapContent(children);
} 