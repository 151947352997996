import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  teams: null
}

export const teams = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setTeams: (state, action) => {
      const teams = action.payload;
      state.teams = teams;
    },
    clear: () => {
      return initialState;
    }
  }
});

const { actions: _actions, reducer } = teams;

// Actions
export const actions = _actions;

// Reducer
export default reducer;