export default (theme) => ({
  base: {
    display: 'flex',
    justifyContent: 'flext-start',
    alignItems: 'center',
    fontSize: '16px',
    lineHeight: '23px',
    fontWeight: '500',
    color: theme.colors.grey2,
    whiteSpace: 'nowrap',
    marginTop: '20px',
    marginBottom: props => props?.gutterBottom ? '35px' : '20px',

    '& span': {
      marginRight: '15px',
    }
  },
  line: {
    height: '1px',
    width: '100%',
    borderTop: `solid 1px ${theme.colors.grey3}`,
  }
})