import React from "react";
import cn from "classnames";
import {
  useEffect,
  useState,
  useHistory,
  useParams,
  useStyle,
} from "utils/hooks";
import {
  Navigation,
  Typo,
  Icon,
  Container,
  Alert,
  Col,
  Input,
  Button,
} from "components";
import routes from "utils/routes";
import { getSeededStyle } from "utils/helper";
import Emoji from "a11y-react-emoji";
import { tracks } from "data";
import Countdown from "react-countdown";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";
import { useAuth } from "utils/AuthContext";
import styles from "./styles";

import firebase from "utils/firebase";
const db = firebase.database();

export default function Login(props) {
  const classes = useStyle(styles, props);
  const [form, setForm] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { login } = useAuth();

  const history = useHistory();

  const setData = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    const data = {
      ...(form || {}),
      [name]: value,
    };

    setForm(data);
  };

  const goBack = () => {
    history.replace(routes.TOURNAMENT);
  };

  const submit = async (e) => {
    e.preventDefault();

    const { email, password } = form || {};

    setError("");
    if (email && password) {
      try {
        setLoading(true);
        await login(email, password);
        history.push(routes.TOURNAMENT);
      } catch {
        setError("Ce compte n'existe pas");
      }
    }
    setLoading(false);
  };

  return (
    <Layout className={classes.base}>
      <Helmet>
        <title>Connexion</title>
      </Helmet>
      {/* <Icon
        name="arrow-down"
        color="black"
        className={classes.backIcon}
        size={25}
        onClick={goBack}
      /> */}
      <Container className={classes.body}>
        <h1>Se Connecter</h1>
        {error && <Alert intent="error">{error}</Alert>}
        <form onSubmit={submit}>
          <Input
            label="Email"
            name="email"
            type="email"
            value={form?.email}
            onChange={(e) => setData(e)}
          />
          <Input
            label="Mot de passe"
            name="password"
            type="password"
            value={form?.password}
            onChange={(e) => setData(e)}
          />
          <Button type="submit" loading={loading}>
            Entrer
          </Button>
        </form>
      </Container>
    </Layout>
  );
}
