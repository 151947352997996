import { ReactComponent as Add } from './svgs/add.svg';
import { ReactComponent as ArrowDown } from './svgs/arrow-down.svg';
import { ReactComponent as BarChart } from './svgs/bar-chart.svg';
import { ReactComponent as Bell } from './svgs/bell.svg';
import { ReactComponent as Calendar } from './svgs/calendar.svg';
import { ReactComponent as Cart } from './svgs/cart.svg';
import { ReactComponent as Chat } from './svgs/chat.svg';
import { ReactComponent as Chevron } from './svgs/chevron.svg';
import { ReactComponent as CreditCard } from './svgs/credit-card.svg';
import { ReactComponent as Download } from './svgs/download.svg';
import { ReactComponent as Edit } from './svgs/edit.svg';
import { ReactComponent as Email } from './svgs/email.svg';
import { ReactComponent as Error } from './svgs/error.svg';
import { ReactComponent as Eye } from './svgs/eye.svg';
import { ReactComponent as Filter } from './svgs/filter.svg';
import { ReactComponent as Folder } from './svgs/folder.svg';
import { ReactComponent as Garage } from './svgs/garage.svg';
import { ReactComponent as Help } from './svgs/help.svg';
import { ReactComponent as Home } from './svgs/home.svg';
import { ReactComponent as Image } from './svgs/image.svg';
import { ReactComponent as Inbox } from './svgs/inbox.svg';
import { ReactComponent as Info } from './svgs/info.svg';
import { ReactComponent as Map } from './svgs/map.svg';
import { ReactComponent as NoConnection } from './svgs/no-connection.svg';
import { ReactComponent as PaperPlane } from './svgs/paper-plane.svg';
import { ReactComponent as Pause } from './svgs/pause.svg';
import { ReactComponent as Pin } from './svgs/pin.svg';
import { ReactComponent as Play } from './svgs/play.svg';
import { ReactComponent as Search } from './svgs/search.svg';
import { ReactComponent as Share } from './svgs/share.svg';
import { ReactComponent as Star } from './svgs/star.svg';
import { ReactComponent as Success } from './svgs/success.svg';
import { ReactComponent as Upload } from './svgs/upload.svg';
import { ReactComponent as User } from './svgs/user.svg';
import { ReactComponent as Users } from './svgs/users.svg';
import { ReactComponent as Volume } from './svgs/volume.svg';
import { ReactComponent as Zap } from './svgs/zap.svg';

export default {
  "add": Add,
  "arrow-down": ArrowDown,
  "bar-chart": BarChart,
  "bell": Bell,
  "calendar": Calendar,
  "cart": Cart,
  "chat": Chat,
  "chevron": Chevron,
  "credit-card": CreditCard,
  "download": Download,
  "edit": Edit,
  "email": Email,
  "error": Error,
  "eye": Eye,
  "filter": Filter,
  "folder": Folder,
  "garage": Garage,
  "help": Help,
  "home": Home,
  "image": Image,
  "inbox": Inbox,
  "info": Info,
  "map": Map,
  "no-connection": NoConnection,
  "paper-plane": PaperPlane,
  "pause": Pause,
  "pin": Pin,
  "play": Play,
  "search": Search,
  "share": Share,
  "star": Star,
  "success": Success,
  "upload": Upload,
  "user": User,
  "users": Users,
  "volume": Volume,
  "zap": Zap,
};
