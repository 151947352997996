export default (theme) => ({
  base: {
    backgroundColor: "white",
    minHeight: "100vh",
    width: "50vw",
    margin: "0 auto",
  },
  body: {
    padding: "20px",
  },
  addNew: {
    display: "flex",
    justifyContent: "stretch",
    alignItems: "flex-start",

    "& button": {
      marginLeft: "8px",
    },
  },
  championship: {
    padding: "20px 10px",
    backgroundColor: theme.colors.grey1,
    cursor: "pointer",
    marginBottom: "5px",
    borderRadius: "5px",
    position: "relative",

    "& p": {
      margin: "0",
    },

    "&:hover": {
      backgroundColor: theme.colors.grey2,
    },
  },
  "championship--done": {},
  completed: {
    position: "absolute",
    right: "14px",
    top: "calc(50% - 10px)",
    fontSize: "20px",
  },
  hiddenEye: {
    position: "absolute",
    right: "10px",
    top: "calc(50% - 9px)",
    height: "18px",
  },
  archived: {
    padding: "10px 10px",
    opacity: 0.5,
  },
  returnToApp: {
    position: "fixed",
    bottom: "-20px",
    right: "0px",
    padding: "20px",
    color: "black",
    cursor: "pointer",
    opacity: 0.7,

    "&:hover": {
      opacity: 1,
      color: theme.colors.primary,
    },
  },
  logout: {
    position: "fixed",
    bottom: "-20px",
    left: "0px",
    padding: "20px",
    color: "black",
    cursor: "pointer",
    opacity: 0.21,

    "&:hover": {
      opacity: 0.7,
      color: theme.colors.red,
    },
  },
  backIcon: {
    transform: "rotate(90deg)",
    position: "fixed",
    top: "20px",
    left: "20px",
    zIndex: "100",
  },
  "@media (max-width: 950px)": {
    base: {
      width: "90vw",
    },
    body: {
      padding: "0px !important",
      margin: "0px",
    },
  },
});
