import React from "react";
import cn from "classnames";
import { useEffect, useState, useHistory, useStyle } from "utils/hooks";
import {
  Navigation,
  Typo,
  Container,
  Icon,
  Row,
  Col,
  Input,
  Button,
} from "components";
import routes from "utils/routes";
import Emoji from "a11y-react-emoji";
import Countdown from "react-countdown";
import { Helmet } from "react-helmet";
import { useAuth } from "utils/AuthContext";
import Layout from "components/Layout";
import styles from "./styles";

import firebase from "utils/firebase";
const db = firebase.database();

export default function Championships(props) {
  const classes = useStyle(styles, props);
  const { logout } = useAuth();
  const [championships, setChampionships] = useState([]);
  const [championshipName, setChampionshipName] = useState("");

  const history = useHistory();

  useEffect(() => {
    const ref = db.ref("championships");

    ref.on("value", (snapshot) => {
      const ch = snapshot.val();
      const list = [];
      Object.keys(ch || {}).forEach((v) => {
        list.push({
          id: v,
          ...(ch[v] || {}),
        });
      });

      const reversed = [].concat(list).reverse();
      setChampionships(reversed);
    });

    return () => {
      ref.off();
    };
  }, []);

  const addChampionship = () => {
    const championshipsDbRef = db.ref("championships");
    const newChampionshipRef = championshipsDbRef.push();
    const imageIndex = Math.ceil(Math.random() * 49 + 1);

    newChampionshipRef.set({
      name: championshipName,
      races: [],
      imageIndex: imageIndex,
      visible: false,
      archived: false,
    });

    setChampionshipName("");
  };

  const back = async () => {
    history.push(routes.TOURNAMENT);
  };

  const backOut = async () => {
    try {
      await logout();
    } catch {
      console.error("logout failed");
    }
    history.push(routes.LOGIN);
  };

  return (
    <Layout className={classes.base}>
      <Helmet>
        <title>Admin - Championnats</title>
      </Helmet>
      {/* <Icon name="arrow-down" color='black' className={classes.backIcon} size={25} onClick={() => history.push(routes.TOURNAMENT)} /> */}
      <Container className={classes.body}>
        <div className={classes.addNew}>
          <Input
            placeholder="Nouveau championnat"
            value={championshipName}
            onChange={(e) => setChampionshipName(e.target.value)}
          />
          <Button disabled={!championshipName} onClick={addChampionship}>
            Ajouter
          </Button>
        </div>

        <h1>Tous les Championnats</h1>
        {championships
          ?.filter?.((c) => !c?.archived)
          .map((c) => {
            const isDone =
              c?.races?.length > 0 && !c.races.find((r) => !r.winner);
            return (
              <div
                key={c?.id}
                className={cn(classes.championship, {
                  [classes["championship--done"]]: isDone,
                })}
                onClick={() =>
                  history.push(routes.ADMIN_CHAMPIONSHIP_FUNC(c?.id))
                }
              >
                <p>
                  {c?.name}
                  {c?.date
                    ? ` on ${new Date(c?.date).toLocaleDateString()}`
                    : ""}
                </p>
                {!c?.visible && (
                  <img
                    className={classes.hiddenEye}
                    src={require("assets/hidden.png")?.default}
                  />
                )}
                {isDone && <p className={classes.completed}>✅</p>}
              </div>
            );
          })}
        <h3 style={{ marginTop: "20px" }}>Archivé</h3>
        {championships
          ?.filter?.((c) => c?.archived)
          .map((c) => {
            return (
              <div
                key={c?.id}
                className={cn(classes.championship, classes.archived)}
                onClick={() =>
                  history.push(routes.ADMIN_CHAMPIONSHIP_FUNC(c?.id))
                }
              >
                <p>{c?.name}</p>
                {!c?.visible && (
                  <img
                    className={classes.hiddenEye}
                    src={require("assets/hidden.png")?.default}
                  />
                )}
              </div>
            );
          })}
      </Container>
      <p onClick={back} className={classes.returnToApp}>
        Retour à l&rsquo;app
      </p>
      <p onClick={backOut} className={classes.logout}>
        Se déconnecter
      </p>
    </Layout>
  );
}
