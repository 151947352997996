export default (theme) => ({
  base: {
    backgroundColor: 'white',
    minHeight: '100vh',
    width: '50vw',
    margin: '0 auto',

    '& button': {
      marginRight: '5px'
    }
  },
  body: {
   padding: '20px',
  },
  races: {
    padding: '20px'
  },
  race: {
    backgroundColor: theme.colors.grey1,
    padding: '15px',
    marginBottom: '10px',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: '5px',

    '& p': {
      margin: '4px'
    },

    '&:hover': {
      backgroundColor: theme.colors.primaryLight
    }
  },
  raceDone: {
    backgroundColor: 'rgba(170,210,170,1)',

    '&:hover': {
      backgroundColor: 'rgba(120,230,120,1)',
    }
  },
  imgPreviewContainer: {
    position: 'relative',
    width: '200px',
    height: '130px',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    borderRadius: '5px',
    marginBottom: '20px',
    overflow: 'hidden'
  },
  imageOverlay: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    zIndex: '10',
    opacity: '0.3',
    filter: 'saturate(500%)',
  },
  imageOverlayBlack: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    zIndex: '11',
    opacity: '0.2',
    backgroundColor: 'black'
  },
  imgPreview: {
    height: '100px',
  },
  label: {
    marginRight: '5px',
    fontWeight: 'bold',
    color: theme.colors.grey3
  },
  val: {

  },
  val2: {
    marginLeft: '10px',
    color: theme.colors.blue
  },
  val3: {
    marginLeft: '10px',
  },
  raceNo: {
    position: 'absolute',
    left: '-25px',
    top: 'calc(50% - 8px)',
    fontSize: '15px',
    fontWeight: 'bold',
    textAlign: 'right'
  },
  addIcon: {
    transition: 'all 0.1s',
    marginLeft: '6px',
    position: 'relative',
    top: '2px',

    '&:hover': {
      transform: "scale(1.2)"
    }
  },
  winner: {
    position: 'absolute',
    right: '10px',
    top: '14px',
    fontSize: '15px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#9e7013',
  },
  backIcon: {
    transform: 'rotate(90deg)',
    position: 'fixed',
    top: '20px',
    left: '20px',
    zIndex: '100'
  },
  '@media (max-width: 950px)': {
    base: {
      width: '90vw',
      marginTop: '30px'
    },
    body: {
      padding: '0px !important',
      margin: '0px'
    },
    backIcon: {
      top: '15px',
      left: '8px',
    },
  }
}) 