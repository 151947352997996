import {
  Intent,
  Button,
} from '@blueprintjs/core';
import cn from 'classnames';
import { useHistory, useStyle } from 'utils/hooks';
import Icon from '../Icon';
import styles from './styles';

const _Button = (props) => {
  const {
    active,
    alignText,
    className,
    disabled,
    elementRef,
    fill,
    icon,
    intent,
    large,
    loading,
    minimal,
    onClick,
    href,
    outlined,
    rightIcon,
    text,
    type,
    children,
    ...rest
  } = props;
  const history = useHistory();
  const classes = useStyle(styles, props);
  // const _intent = !!error ? Intent.DANGER : intent;
  // const _leftIcon = leftIcon ? <div className={classes.iconWrapper}><Icon name={leftIcon} className={classes.icon} color={!!error ? 'red' : 'grey2'} /></div> : null;
  // const _rightIcon = rightIcon ? <div className={classes.iconWrapper}><Icon name={rightIcon} className={classes.icon} color={!!error ? 'red' : 'grey2'} /></div> : null;
  return (
    <Button
      active={active}
      alignText={alignText}
      className={cn(classes.button, className)}
      disabled={disabled}
      elementRef={elementRef}
      fill={fill}
      icon={icon}
      intent={intent || Intent.PRIMARY}
      large={large}
      loading={loading}
      minimal={minimal}
      onClick={href ? () => history.push(href) : onClick}
      outlined={outlined}
      rightIcon={rightIcon}
      text={text}
      type={type}
    >
      {children}
    </Button>
  )
};

export default _Button;