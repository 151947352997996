import cn from 'classnames';
import Icon from '../Icon';
import Typo from '../Typo';
import { Row } from '../';
import { useStyle } from 'utils/hooks';
import styles from './styles';

const Alert = (props) => {
  const {
    intent = 'primary',
    children,
    icon = true,
    className
  } = props;
  const classes = useStyle(styles, props);
  const iconName = {
    primary: 'success',
    error: 'error',
    warning: 'error',
    info: 'bell',
  }[intent] || 'success';
  const prefix = {
    primary: 'Success: ',
    error: 'Error: ',
    warning: 'Warning: ',
    info: 'Info: ',
  }[intent];
  return (
    <div className={cn(classes.base, className)}>
      {icon && <Icon name={iconName} size={20} className={classes.icon} />}
      <Typo type="span" align="left">{prefix}{children}</Typo>
    </div>
  )
};

export default Alert;