export default (theme) => {
  return {
    base: {
      textAlign: props => props?.align,
      fontWeight: props => props?.weight,
      display: props => props?.display,
      color: props => {
        return props?.color ? theme?.colors[props.color] : null
      },
      marginBottom: props => props?.gutterBottom ? '16px' : null,
      // ...(props?.noWrap ? {
      //   whiteSpace: 'nowrap',
      //   overflow: 'hidden',
      //   textOverflow: 'ellipsis',
      // }: {})
    },
    // extended types
    'title': {
      ...(theme?.typo?.['title'] || {}),
    },
    // all types
    'type-title-hero': {
      ...(theme?.typo?.['title-hero'] || {}),
    },
    'type-title-1': {
      ...(theme?.typo?.['title-1'] || {}),
    },
    'type-title-2': {
      ...(theme?.typo?.['title-2'] || {}),
    },
    'type-title-3': {
      ...(theme?.typo?.['title-3'] || {}),
    },
    'type-title-4': {
      ...(theme?.typo?.['title-4'] || {}),
    },
    'type-title-5': {
      ...(theme?.typo?.['title-5'] || {}),
    },
    'type-text': {
      ...(theme?.typo?.['text'] || {}),
    },
    'type-text-small': {
      ...(theme?.typo?.['text-small'] || {}),
    },
    'type-span': {
      fontWeight: 'inherit',
      fontSize: 'inherit',
    }
  }
}