export default (theme) => ({
  base: {
    backgroundColor: "white",
    minHeight: "100vh",
    width: "50vw",
    margin: "0 auto",

    "& button": {
      marginRight: "5px",
    },

    "& h2": {
      marginTop: "16px",
      marginBottom: "8px",
    },
  },
  body: {
    padding: "20px",
  },
  races: {
    backgroundColor: "rgb(230,230,230)",
    padding: "20px",
  },
  raceLength: {
    color: theme.colors.grey1,
    margin: "0 0 16px",
    position: "relative",
  },
  backIcon: {
    transform: "rotate(90deg)",
    position: "fixed",
    top: "20px",
    left: "20px",
    zIndex: "100",
  },
  carSelectContainer: {
    display: "flex",
    justifyContent: "stretch",
    alignItems: "flex-end",

    "& img": {
      minWidth: "100px",
      maxWidth: "100px",
      minHeight: "42px",
      maxHeight: "42px",
      objectFit: "cover",
      marginLeft: "8px",
      borderRadius: "8px",
      marginBottom: "15px",
      cursor: "pointer",
    },
  },
  inputRow: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "10px",
  },
  input: {
    width: "100%",
  },
  carInput: {
    flex: 1,
  },
  nicknameInput: {
    flex: 0.35,
    marginLeft: "8px",
    minWidth: "75px",
  },
  openCarOverlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 1000,

    "& img": {
      maxWidth: "calc(100% - 60px)",
      maxHeight: "calc(100% - 60px)",
      margin: "50px",
    },

    "& p": {
      position: "fixed",
      bottom: "25px",
      width: "100vw",
      textAlign: "center",
      color: "white",
      fontSize: "12px",
    },
  },
  "@media (max-width: 950px)": {
    base: {
      width: "90vw",
      marginTop: "30px",
    },
    body: {
      padding: "0px !important",
      margin: "0px",
    },
    backIcon: {
      top: "15px",
      left: "8px",
    },
  },
});
