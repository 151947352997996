export default (theme) => ({
  '@global': {
    '.bp3-input-action, .bp3-input-left-container': {
      height: '100%',
    },
    '.bp3-form-helper-text': {
      textAlign: 'left',
      color: theme.colors.grey4,
    },
    '.bp3-form-group.bp3-intent-primary .bp3-form-helper-text': {
      color: theme.colors.primary,
    },
    '.bp3-form-group.bp3-intent-danger .bp3-form-helper-text': {
      color: theme.colors.red,
    },
    '.bp3-label': {
      textAlign: 'left',
      textTransform: 'uppercase',
      color: 'black',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 500,
      letterSpacing: '1px',
    },
    '.bp3-form-group.bp3-intent-primary .bp3-label': {
      color: theme.colors.primary,
    },
    '.bp3-form-group.bp3-intent-danger .bp3-label': {
      color: theme.colors.red,
    },

    '.bp3-input-group .bp3-input:focus': {
      boxShadow: `0px 0px 0px 2px ${theme.colors.grey2}`,
    },
    '.bp3-input-group.bp3-intent-primary .bp3-input': {
      boxShadow: `0px 0px 0px 2px ${theme.colors.primary}`,
    },
    '.bp3-input-group.bp3-intent-primary .bp3-input:focus': {
      boxShadow: `0px 0px 0px 2px ${theme.colors.primaryDark}`,
    },
    '.bp3-input-group.bp3-intent-danger .bp3-input': {
      boxShadow: `0px 0px 0px 2px ${theme.colors.red}`,
    },
    '.bp3-input-group.bp3-intent-danger .bp3-input:focus': {
      boxShadow: `0px 0px 0px 2px ${theme.colors.red}`,
    },
    '.bp3-input:disabled, .bp3-input.bp3-disabled': {

    }
  },
  formGroup: {
    '& label': {
      
    },
    '& textarea': {
      borderRadius: '8px',
      padding: '10px 15px',
      height: 'auto',
      minHeight: '80px',
      fontSize: '14px',
      lineHeight: '21px',
      color: 'black',
      resize: 'none',
      width: '500px',

      '::placeholder': {
        color: theme.colors.grey2,
      }
    }
  },
  inputGroup: {
    '& input': {
      borderRadius: '8px',
      padding: '10px 15px',
      height: 'auto',
      fontSize: '14px',
      lineHeight: '21px',
      color: 'black',

      '::placeholder': {
        color: theme.colors.grey2,
      }
    }
  },
  iconWrapper: {
    padding: '0px 20px',
  },
  icon: {
    margin: '0px',
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
  }
})