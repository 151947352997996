import seedrandom from 'seedrandom';

export const getSeededStyle = (seed: string) => {
  const random = seedrandom(seed);

  const r = Math.floor(random() * 240);
  const g = Math.floor(random() * 240);
  const b = Math.floor(random() * 240);

  const factor = 70;
  const r2 =  Math.min(255, r + factor);
  const g2 =  Math.min(255, g + factor);
  const b2 =  Math.min(255, b + factor);

  const angle = random() * 150 - 75;

  return {
    rgbMain: `rgb(${r}, ${g}, ${b})`,
    rgbLight: `rgb(${r2}, ${g2}, ${b2})`,
    angle: `${angle}`
  }
};