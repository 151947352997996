import { Intent, FormGroup, InputGroup, TextArea } from "@blueprintjs/core";
import cn from "classnames";
import Icon from "../Icon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useStyle } from "utils/hooks";
import styles from "./styles";

const InputDate = (props) => {
  const {
    // FormGroup
    inline = false,
    label,
    labelInfo,
    labelFor,
    helperText,
    intent,
    className,
    // InputGroup
    defaultValue,
    value,
    placeholder,
    disabled,
    fill,
    inputRef,
    small,
    large,
    leftIcon,
    rightIcon,
    onLeftIconClick,
    onRightIconClick,
    onChange,
    multiple,
    options,
    inputClassName,
    // Custom
    area = false,
    error,
    valid,
    // Rest
    ...rest
  } = props;
  const classes = useStyle(styles, props);
  const _intent = error ? Intent.DANGER : valid ? Intent.PRIMARY : intent;

  return (
    <FormGroup
      inline={inline}
      label={label}
      labelInfo={labelInfo}
      labelFor={labelFor}
      intent={_intent}
      helperText={error || helperText}
      className={cn(className, classes.formGroup)}
    >
      <DatePicker
        className={styles.select}
        selected={value}
        onChange={(date) => onChange(date)}
        {...rest}
      />
    </FormGroup>
  );
};

export default InputDate;
