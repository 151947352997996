import React from "react";
import cn from "classnames";
import { useEffect, useState, useRef, useStyle } from "utils/hooks";
import { Navigation, Typo, Container, Row, Col } from "components";
import useWindowSize from "react-use/lib/useWindowSize";
import Emoji from "a11y-react-emoji";
import Confetti from "react-confetti";
import Countdown from "react-countdown";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";
import styles from "./styles";

const dateYear = 2022,
  dateMonth = 3,
  dateDay = 3,
  dateHour = 12,
  dateMinute = 5;

export default function Home(props) {
  const classes = useStyle(styles, props);
  const [ready, setReady] = useState(false);
  const { width, height } = useWindowSize();

  useEffect(() => {
    check();
  }, []);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <>
          {days} jours, {hours} heures, {minutes} minutes et {seconds} secondes
          <br />
        </>
      );
    }
  };

  const check = () => {
    const ready =
      new Date(dateYear, dateMonth, dateDay, dateHour, dateMinute).getTime() <
      new Date().getTime();
    setReady(ready);
  };

  return (
    <Layout className={classes.base}>
      <Helmet>{/* <title>Home</title> */}</Helmet>
      <div className={classes.top}>
        <Navigation ready={ready} />
        <Container>
          <Typo
            type="title-hero"
            color="white"
            align="center"
            style={{ marginTop: "60px", userSelect: "none" }}
          >
            {ready ? (
              <>
                <Emoji symbol="🎉" label="party" /> C&rsquo;EST TA FÊTE WOOHOO!!{" "}
                <Emoji symbol="🎉" label="party" />
              </>
            ) : (
              <>
                <Emoji symbol="😎" label="party" /> Ta fête arrive bientôt!{" "}
                <Emoji symbol="😎" label="party" />
              </>
            )}
          </Typo>
          <div className={classes.countdown} style={{ userSelect: "none" }}>
            <Countdown
              date={
                new Date(dateYear, dateMonth, dateDay, dateHour, dateMinute)
              }
              renderer={renderer}
              onTick={check}
              onComplete={check}
            />
          </div>
        </Container>
      </div>
      <div className={classes.body}>
        {!ready && (
          <Container>
            <p>
              Mon cher René, c&rsquo;est à ton tourrrr, de te laisserrrr,
              jouerrrr.. à des jeux vidéos!
            </p>
            <p>
              En honneur de ta fête bien spéciale de 2022, je t&rsquo;ai créé
              une page web de Gran-Turismo juste pouuu touaa. Quand ca sera ta
              fête, cette page va changer pour révéler plus d&rsquo;information,
              et te fera pleurer de joie parce que ton fils est incroyable. La
              grosse surprise: ici, nous pourrons organiser et créer des
              championnats Gran Turismo 7 père & fils à distance, et suivre
              notre progrès! Mais tu y auras accès seulement le 4 avril!{" "}
              <Emoji symbol="😎" label="party" />
            </p>
            <p>
              Essaye pas de tricher et de consulter le{" "}
              <b style={{ textDecoration: "underline", color: "#a68a1c" }}>
                Tournoi Gran Turismo 7
              </b>{" "}
              avant ta fête mon coquain... (essaye donc de cliquer sur le lien
              en haut pour voir)
            </p>
            <p></p>
          </Container>
        )}
        {ready && (
          <Container>
            <p style={{ textAlign: "center" }}>
              J&rsquo;espère que t&rsquo;es prêt a jouer a Gran Turismo 7 mon
              papoute! En addition à avoir ton propre système web de championnat
              juste pouuu touaa, j&rsquo;ai quelques autres petites surprises
              pour toi, qui vont rendre nos tournois père-fils inoubliables!
            </p>
            <div className={classes.giftsContainer}>
              <div className={classes.giftPsPlus}>
                <img src={require("assets/ps-plus.png")?.default} />
                <p>
                  Un abonnement PS PLUS de 1 an! Pour jouer online avec ton
                  fiston tous les jours! Et vu que c&rsquo;est une carte cadeau,
                  tu n&rsquo;auras pas a dealer avec leur customer-service à
                  marde <Emoji symbol="😂" label="lol" />
                </p>
              </div>
              <div className={classes.giftHoodie}>
                <img src={require("assets/hoodie-crop.png")?.default} />
                <p>
                  Un chandail spécial Gran Turismo 7 que j&rsquo;ai créé juste
                  pour toi! Avec ton nom et ton #4! La récompense ultime pour
                  toutes les heures que tu as passé à m&rsquo;apprendre
                  Photoshop! <Emoji symbol="🏎️" label="racecar" />
                </p>
              </div>
            </div>
          </Container>
        )}
      </div>
      <img
        className={classes.g1}
        src={require("assets/guarland-big.png").default}
      />
      <img
        className={classes.g2}
        src={require("assets/guarland-big.png").default}
      />
      <img
        className={classes.crowd}
        src={require("assets/crowd.png").default}
      />
      {ready && <Confetti width={width} height={height} />}
    </Layout>
  );
}
