export default (theme) => ({
  base: {
    backgroundColor: 'white',
    minHeight: '100vh',

    '& h1': {
      marginBottom: '16px'
    }
  },
  body: {
   padding: '20px'
  },
  nav: {
    '& ul': {
      '& li': {
        listStyle: 'none',
        display: 'inline',
        padding: '4px 0px',
        margin: '0 16px',
        cursor: 'pointer',
        color: 'black',

        '&:hover': {
          borderColor: theme.colors.blue,
          '& a': {
            color: theme.colors.blue,
          }
        }
      },
      '& a': {
        fontWeight: 'bold',
        textDecoration: 'none',
        color: 'black',
        userSelect: 'none',
      }
    }
  }
}) 