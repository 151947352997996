import "styles/index.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import { ThemeProvider, defaultTheme } from "styles/theme";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import { PrivateRoute } from "components";
import { useState } from "react";
import routes from "./utils/routes";
import { useAuth } from "utils/AuthContext";
import { AuthProvider } from "./utils/AuthContext";
import {
  Home,
  Login,
  Tournament,
  Championship,
  Garage,
  Admin,
  Birthday2023,
} from "./pages";
import { useEffect } from "react";

function _App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <AuthProvider>
        <CustomRouter />
      </AuthProvider>
    </ThemeProvider>
  );
}

function CustomRouter() {
  const { currentUser, signInAnonymously } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (!currentUser) {
        await signInAnonymously();
        setLoading(false);
      }
      setLoading(false);
    };
    setLoading(true);
    init();
  });

  if (loading) return null;
  return (
    <Router>
      <Switch>
        <Route exact path={routes.LOGIN} component={Login} />
        <Route path={routes.HOME} component={Home} />
        <PrivateRoute
          exact
          path={routes.CHAMPIONSHIP_ROOT}
          component={Championship}
        />
        <PrivateRoute path={routes.TOURNAMENT} component={Tournament} />
        <PrivateRoute path={routes.GARAGE} component={Garage} />
        <PrivateRoute path={routes.ADMIN} component={Admin} />

        <Route path={routes.BIRTHDAY_2023} component={Birthday2023} />
        <Redirect to={routes.TOURNAMENT} />
      </Switch>
    </Router>
  );
}

export default _App;
