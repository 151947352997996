import {
  Spinner,
} from '@blueprintjs/core';
import cn from 'classnames';
import { useStyle } from 'utils/hooks';
import styles from './styles';

const _Spinner = (props) => {
  const classes = useStyle(styles, props);
  const { size = 100, intent = 'primary' } = props;
  return (
    <Spinner size={size} intent={intent} className={cn(classes.base, props.className)} />
  )
};

export default Spinner;