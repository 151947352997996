import cn from 'classnames';
import { useStyle, useTheme } from 'styles/theme';
import iconMapper from './map';
import styles from './styles';

export default function Icon(props) {
  const {
    id,
    name = '',
    title,
    className,
    style = {},
    size,
    color,
    width = size,
    height = size,
    onClick
  } = props;
  const theme = useTheme();
  const classes = useStyle(styles, props);
  const IconComponent = iconMapper[name] || null;
  const iconProps = {
    id,
    className: cn(classes.icon, className),
    style,
    onClick,
    title,
  };
  if (width) iconProps['width'] = `${width}px`;
  if (height) iconProps['height'] = `${height}px`;

  if (IconComponent) {
    return <IconComponent color={theme.colors[color]} {...iconProps} />
  }
  return null;
} 
