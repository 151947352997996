export default (theme) => ({
  base: {
    paddingTop: '16px',
    marginBottom: '100px',

    '& ul': {
      '& li': {
        listStyle: 'none',
        display: 'inline',
        padding: '4px 0px',
        margin: '0 16px',
        cursor: 'pointer',
        color: theme.colors.white,

        '&:hover': {
          '& a': {
            color: 'gold',
          }
        }
      },
      '& a': {
        fontWeight: 'bold',
        textDecoration: 'none',
        color: theme.colors.white,
        userSelect: 'none',
      }
    }
  },
  logo: {
    cursor: 'pointer',
  },
})