export default {
  HOME: "/accueil",
  TOURNAMENT: "/tournoi",
  GARAGE: "/garage",
  ADMIN: "/admin",
  LOGIN: "/login",
  ADMIN_CHAMPIONSHIPS: "/admin/championships",
  ADMIN_CHAMPIONSHIP_ROOT: "/admin/championships/:id",
  ADMIN_CHAMPIONSHIP: "/admin/championships/",
  ADMIN_CHAMPIONSHIP_FUNC: (id) => `/admin/championships/${id}`,
  ADMIN_CHAMPIONSHIP_RACE_ROOT: "/admin/championships/:id/race",
  ADMIN_CHAMPIONSHIP_RACE_FUNC: (id) => `/admin/championships/${id}/race`,
  ADMIN_CHAMPIONSHIP_RACE_SPECIFIC_FUNC: (id, id2) =>
    `/admin/championships/${id}/race/${id2}`,

  CHAMPIONSHIP_ROOT: "/tournoi/:id",
  CHAMPIONSHIP_FUNC: (id) => `/tournoi/${id}`,

  BIRTHDAY_2023: "/2023",
};
