
import cn from 'classnames';
import { useStyle } from 'styles/theme';
import { Helmet } from "react-helmet";
import styles from './styles';

export default function Layout(props) {
  const { className, children } = props;
  const classes = useStyle(styles, props);
  return (
    <div className={cn(classes.base, className)}>
      <Helmet>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      
      <main>{children}</main>
    </div>
  )
} 