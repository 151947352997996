import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import { useHistory, useStyle } from "utils/hooks";
import { Container, PrivateRoute } from "components";
import routes from "utils/routes";
import Layout from "components/Layout";
import Championship from "./Championship";
import Championships from "./AllChampionships";
import Race from "./Race";
import styles from "./styles";

import firebase from "utils/firebase";
const db = firebase.database();

export default function Admin(props) {
  const classes = useStyle(styles, props);
  const history = useHistory();

  return (
    <Layout className={classes.base}>
      {/* <div className={classes.nav}>
        <Row>
          <ul>       
            <li onClick={() => history.push(routes.ADMIN_CHAMPIONSHIPS)}>
              <a>Championships</a>
            </li>
          </ul>
        </Row>
      </div> */}
      <Container className={classes.body}>
        <Switch>
          <PrivateRoute
            exact
            path={routes.ADMIN_CHAMPIONSHIP_ROOT}
            component={Championship}
          />
          <PrivateRoute
            exact
            path={routes.ADMIN_CHAMPIONSHIPS}
            component={Championships}
          />
          <PrivateRoute
            exact
            path={routes.ADMIN_CHAMPIONSHIP_RACE_ROOT}
            component={Race}
          />
          <Redirect to={routes.ADMIN_CHAMPIONSHIPS} />
        </Switch>
      </Container>
    </Layout>
  );
}
