export default (theme) => ({
  '@global': {
    '.bp3-button': {
      padding: '11px 20px',
      borderRadius: '8px',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '18px',
      color: theme.colors.black,
      textTransform: 'uppercase',
      letterSpacing: '1px',
      outline: 'none',
      pointerEvents: props => props.loading ? 'none' : 'all',
      flexGrow: '0',
    },
    '.bp3-button.bp3-intent-primary': {
      backgroundColor: theme.colors.primary,
      color: theme.colors.black,
    },
    '.bp3-button.bp3-intent-primary:hover': {
      backgroundColor: theme.colors.primaryLight,
      color: theme.colors.black,
    },
    '.bp3-button.bp3-intent-primary:active': {
      backgroundColor: theme.colors.primaryDark,
      color: theme.colors.black,
    },
    '.bp3-button.bp3-intent-primary:focus': {
      backgroundColor: theme.colors.primaryDark,
      color: theme.colors.black,
    },
    '.bp3-button.bp3-intent-primary:disabled': {
      backgroundColor: theme.colors.primaryLight,
      opacity: 0.7,
      color: theme.colors.black,
    },
    '.bp3-button.bp3-large': {
      padding: '16px 20px',
    },

    '.bp3-button.bp3-intent-info': {
      backgroundColor: theme.colors.grey4,
      color: theme.colors.white,
    },
    '.bp3-button.bp3-intent-info:hover': {
      backgroundColor: theme.colors.grey3,
      color: theme.colors.white,
    },
    '.bp3-button.bp3-intent-info:active': {
      backgroundColor: theme.colors.black,
      color: theme.colors.white,
    },
    '.bp3-button.bp3-intent-info:focus': {
      backgroundColor: theme.colors.black,
      color: theme.colors.white,
    },
    '.bp3-button.bp3-intent-info:disabled': {
      backgroundColor: theme.colors.grey3,
      opacity: 0.7,
      color: theme.colors.white,
    },
  },
  button: {
    backgroundColor: 'red',
  },
})