import bg from 'assets/blur-bg-alt.jpg';

export default (theme) => ({
  base: {
    minHeight: '100vh',
    maxWidth: '100vw',
    // backgroundColor: theme.colors.primaryLight,
    background: 'linear-gradient(200deg, rgba(238,39,55,1) 0%, rgba(247,50,99,1) 100%)',
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '100px 0',
    textAlign: 'center'

  },

  button: {
    marginTop: '300px',
    border: 'solid 8px white'
  },
  header: {
    "& h1": {
      textAlign: "center",
      fontSize: "60px",
      fontStyle: "italic",
      color: "white",
      marginBottom: "30px",
      userSelect: "none",
      fontFamily: "gtfontoblique",
    },
  },
  body: {
    minHeight: '600px',
    backgroundColor: 'rgba(255,255,255,0.9)',
    margin: '36px auto',
    padding: '40px 40px',
    borderRadius: '8px',
    boxShadow: '0px 2px 10px 4px rgba(0, 0, 0, 0.2)',
    width: '930px',
    maxWidth: '100vw',
    lineHeight: '24px',
    fontSize: '18px',
    textAlign: 'center',

    "& h2": {
      textAlign: "center",
      fontSize: "30px",
      fontStyle: "italic",
      marginBottom: "30px",
      fontFamily: "gtfontoblique",
    },

    '& p': {
      marginBottom: '12px',
    },

    '& b': {
      fontWeight: 'bold',
      fontFamily: 'helvetica',
      letterSpacing: '2.5px',
      fontSize: '20px',
    }
  },
  gibbyslogo: {
    width: '80%'
  },
  gibbyslogo2: {
    height: '80px'
  },
  ch1: {
    position: "fixed",
    right: "-11vw",
    top: "-17vh",
    width: "49.5vw",
    zIndex: 1,
    userSelect: "none",
  },
  ch2: {
    position: "fixed",
    left: "-11vw",
    top: "-17vh",
    transform: "scaleX(-1)",
    width: "49.5vw",
    zIndex: 1,
    userSelect: "none",
  },
})