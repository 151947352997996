import cn from "classnames";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "utils/AuthContext";
import routes from "utils/routes";
import styles from "./styles";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { currentUser } = useAuth();
  return (
    <Route
      {...rest}
      render={(p) => {
        return currentUser && !currentUser.isAnonymous ? (
          <Component {...p} />
        ) : (
          <Redirect to={routes.LOGIN} />
        );
      }}
    />
  );
};

export default PrivateRoute;
