import {
  Intent,
  FormGroup,
  InputGroup,
  TextArea,
} from '@blueprintjs/core';
import cn from 'classnames';
import Icon from '../Icon';
import { default as ExternalSelect } from 'react-dropdown-select';
import { useState, useStyle } from 'utils/hooks';
import styles from './styles';

const InputSelect = (props) => {
  const {
    // FormGroup 
    inline = false,
    label,
    labelInfo,
    labelFor,
    helperText,
    intent,
    className,
    // InputGroup
    defaultValue,
    value,
    placeholder,
    disabled,
    fill,
    inputRef,
    small,
    large,
    leftIcon,
    rightIcon,
    onLeftIconClick,
    onRightIconClick,
    onChange,
    multiple,
    options,
    inputClassName,
    // Custom
    area = false,
    error,
    valid,
    // Rest
    ...rest
  } = props;
  const [show, setShow] = useState();
  const classes = useStyle(styles, props);
  const _intent = error ? Intent.DANGER : (valid ? Intent.PRIMARY : intent);
  const _leftIcon = leftIcon ? <div className={classes.iconWrapper} onClick={onLeftIconClick}><Icon name={leftIcon} className={classes.icon} color={error ? 'red' : (valid ? 'primary' : 'grey2')} /></div> : null;
  const _rightIcon = rightIcon ? <div className={classes.iconWrapper} onClick={onRightIconClick}><Icon name={rightIcon} className={classes.icon} color={error ? 'red' : (valid ? 'primary' : 'grey2')} /></div> : null;
  
  return (
    <FormGroup
      inline={inline}
      label={label}
      labelInfo={labelInfo}
      labelFor={labelFor}
      intent={_intent}
      helperText={error || helperText}
      className={cn(className, classes.formGroup)}
    >
      <ExternalSelect 
        className={styles.select}
        multi={multiple}
        options={options}
        placeholder={placeholder || ""}
        onChange={onChange}
        values={value}
        {...rest}
      />
    </FormGroup>
  )
};

export default InputSelect;