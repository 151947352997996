import React from "react";
import cn from "classnames";
import {
  useEffect,
  useState,
  useHistory,
  useParams,
  useStyle,
} from "utils/hooks";
import {
  Navigation,
  Typo,
  Icon,
  Container,
  Row,
  Col,
  Input,
  InputDate,
  Button,
} from "components";
import routes from "utils/routes";
import { getSeededStyle } from "utils/helper";
import Emoji from "a11y-react-emoji";
import { tracks } from "data";
import Countdown from "react-countdown";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";
import styles from "./styles";

import firebase from "utils/firebase";
const db = firebase.database();

export default function Championship(props) {
  const classes = useStyle(styles, props);
  const [championship, setChampionship] = useState();
  const [name, setName] = useState("");
  const [dirty, setDirty] = useState(false);

  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    const ref = db.ref(`championships/${params?.id}`);

    ref.once("value", (snapshot) => {
      setName(snapshot.val()?.name);
      setChampionship(snapshot.val());
    });
  }, []);

  const setData = (e, checkbox = false) => {
    const name = e?.target?.name;
    let value = checkbox ? e?.target?.checked : e?.target?.value;
    setDirty(true);

    const data = {
      ...(championship || {}),
      [name]: value,
    };

    if (name === "imageIndex" && value) {
      if (value < 1) data[name] = 1;
      if (value > 75) data[name] = 75;
    }

    setChampionship(data);
  };

  const setDateData = (name, value) => {
    setDirty(true);

    const data = {
      ...(championship || {}),
      [name]: value?.getTime(),
    };

    setChampionship(data);
  };

  const update = (skip = false) => {
    const championshipsDbRef = db.ref(`championships/${params?.id}`);
    championshipsDbRef.set(championship);

    if (!skip) history.replace(routes.ADMIN_CHAMPIONSHIPS);
  };

  const del = () => {
    if (
      !confirm(
        "Es-tu certain de vouloir supprimer ce championnat? Tu peux aussi simplement le marquer comme 'archivé' ou 'invisible'. Veux-tu vraiment supprimer le championnat?"
      )
    )
      return;

    const championshipsDbRef = db.ref(`championships/${params?.id}`);
    championshipsDbRef.remove();

    history.replace(routes.ADMIN_CHAMPIONSHIPS);
  };

  const addRace = () => {
    update(true);

    history.push(routes.ADMIN_CHAMPIONSHIP_RACE_FUNC(params?.id));
  };

  const goBack = () => {
    if (dirty) {
      if (
        !confirm(
          "Attention! Tout changement non sauvegardé sera perdu. Veux-tu vraiment quitter la page?"
        )
      )
        return;
    }

    history.push(routes.ADMIN_CHAMPIONSHIPS);
  };

  const getDistanceLabel = (km) => {
    if (!km) return null;
    const st = { color: "rgb(130,130,130)" };

    if (km < 7) return <span style={st}>(court)</span>;
    if (km < 13.5) return <span style={st}>(moyen)</span>;
    if (km < 21) return <span style={st}>(long)</span>;
    if (km >= 21) return <span style={st}>(endurance)</span>;
  };

  const seededStyle = getSeededStyle(championship?.name);

  return (
    <Layout className={classes.base}>
      <Helmet>
        <title>Admin - {name}</title>
      </Helmet>
      <Icon
        name="arrow-down"
        color="black"
        className={classes.backIcon}
        size={25}
        onClick={goBack}
      />
      <Container className={classes.body}>
        <h1>{name}</h1>
        {championship?.imageIndex >= 1 && championship?.imageIndex <= 75 && (
          <div
            className={classes.imgPreviewContainer}
            style={{
              backgroundImage: `url(${
                require(`assets/wallpaper/${championship?.imageIndex}.jpg`)
                  ?.default
              })`,
            }}
          >
            <div
              className={classes.imageOverlay}
              style={{ backgroundColor: `${seededStyle.rgbMain}` }}
            />
            <div className={classes.imageOverlayBlack} />
          </div>
        )}
        <Input
          label="Nom du championnat"
          name="name"
          value={championship?.name}
          onChange={(e) => setData(e)}
        />
        <Input
          label="Description (optionnel)"
          name="description"
          value={championship?.description}
          area
          onChange={(e) => setData(e)}
        />
        <div style={{ marginTop: "10px", color: "blue" }}>
          <label htmlFor="isTimeTrial">Time Trial?</label>
          <input
            name="isTimeTrial"
            id="isTimeTrial"
            type="checkbox"
            onChange={(e) => setData(e, true)}
            checked={championship?.isTimeTrial}
          />
        </div>
        <br />
        <InputDate
          label={
            championship?.isTimeTrial ? "Date d'échéance" : "Date (optionnel)"
          }
          name="date"
          value={championship?.date ? new Date(championship?.date) : null}
          onChange={(d) => setDateData("date", d)}
        />
        <Input
          label="Image Couverture (1-75)"
          name="imageIndex"
          value={championship?.imageIndex}
          pattern="[0-9]*"
          type="number"
          min={1}
          max={75}
          onChange={(e) => setData(e)}
        />

        <br />
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="isVisible">Visible?</label>
          <input
            name="visible"
            id="isVisible"
            type="checkbox"
            onChange={(e) => setData(e, true)}
            checked={championship?.visible}
          />
        </div>
        <div style={{ marginBottom: "30px" }}>
          <label htmlFor="isArchived">Archivé?</label>
          <input
            name="archived"
            id="isArchived"
            type="checkbox"
            onChange={(e) => setData(e, true)}
            checked={championship?.archived}
          />
        </div>
      </Container>
      <Container className={classes.races}>
        <h1>
          {championship?.isTimeTrial ? "Time Trials" : "Courses"}{" "}
          <Icon name="add" className={classes.addIcon} onClick={addRace} />
        </h1>
        {(championship?.races || []).map((race, i) => {
          const selectedTrackRaw = race?.track
            ? tracks?.find((t) => t.name === race?.track)
            : null;
          const raceLengthKm =
            selectedTrackRaw && race?.laps > 0
              ? (selectedTrackRaw?.km * race?.laps || "").toFixed(3)
              : null;

          return (
            <div
              key={race?.id}
              className={cn(classes.race, {
                [classes.raceDone]: !!race?.winner,
              })}
              onClick={() =>
                history.push({
                  pathname: routes.ADMIN_CHAMPIONSHIP_RACE_FUNC(params?.id),
                  state: {
                    raceId: race?.id,
                    champId: params?.id,
                  },
                })
              }
            >
              <p className={classes.raceNo}>{i + 1}.</p>
              {race?.name && (
                <p>
                  <span className={classes.label}>Nom:</span>
                  <span className={classes.val}>{race?.name}</span>
                </p>
              )}
              <p>
                <span className={classes.label}>Piste:</span>
                <span className={classes.val}>{race?.track}</span>
                {race?.reverse && (
                  <span
                    style={{
                      paddingLeft: "4px",
                      verticalAlign: "super",
                      fontSize: "10px",
                    }}
                  >
                    reverse
                  </span>
                )}
                {!championship?.isTimeTrial && (
                  <span className={classes.val2}>
                    {race?.laps} tour{race?.laps > 1 ? "s" : ""}{" "}
                    {getDistanceLabel(raceLengthKm)}
                  </span>
                )}
              </p>
              {race?.carRene && (
                <p>
                  <span className={classes.label}>Voiture René:</span>
                  <span className={classes.val}>{race?.carRene}</span>
                </p>
              )}
              {race?.carLaurel && (
                <p>
                  <span className={classes.label}>Voiture Laurel:</span>
                  <span className={classes.val}>{race?.carLaurel}</span>
                </p>
              )}
              {race?.restrictions && (
                <p>
                  <span className={classes.label}>Restrictions:</span>
                  <span
                    style={{ color: "rgb(100,0,0)" }}
                    className={classes.val}
                  >
                    {race?.restrictions}
                  </span>
                </p>
              )}
              {race?.finishNotes && (
                <p>
                  <span className={classes.label}>Notes:</span>
                  <span
                    style={{ color: "rgb(0,100,0)" }}
                    className={classes.val}
                  >
                    {race?.finishNotes}
                  </span>
                </p>
              )}
              {race?.winner && (
                <p className={classes.winner}>
                  {race?.winner} <Emoji symbol="🏆" label="Winner" />
                </p>
              )}
            </div>
          );
        })}
      </Container>

      <div
        style={{
          width: "100%",
          borderBottom: "solid 1px lightgray",
          marginBottom: "24px",
        }}
      />

      <Button onClick={del} intent="error">
        Supprimer
      </Button>
      <Button disabled={!dirty} onClick={update}>
        Sauvegarder
      </Button>
    </Layout>
  );
}
