import bg from "assets/blur-bg.jpg";

export default (theme) => ({
  "@keyframes grow": {
    "0%": {
      transform: "scale(0.9)",
    },
    "50%": {
      transform: "scale(1.5)",
    },
    "100%": {
      transform: "scale(0.9)",
    },
  },
  base: {
    minHeight: "100vh",
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
    paddingTop: "50px",
    paddingBottom: "50px",

    "& h1": {
      textAlign: "center",
      fontSize: "60px",
      fontStyle: "italic",
      color: "white",
      marginBottom: "30px",
      userSelect: "none",
    },
  },
  backIcon: {
    transform: "rotate(90deg)",
    position: "absolute",
    top: "30px",
    left: "20px",
    zIndex: "100",
  },
  content: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexWrap: "wrap",
    zIndex: 20,
    width: "calc(90vw)",
    margin: "0 auto",
    borderRadius: "8px",
    backgroundColor: "rgba(0,0,0,0.5)",
    textAlign: "center",
    padding: "20px",
  },
  inputContainer: {
    width: "300px",
    margin: "0 auto",
    marginBottom: "30px",
  },
  carContainer: {
    width: "calc(20% - 16px)",
    minWidth: "calc(20% - 16px)",
    maxWidth: "calc(20% - 16px)",
    height: "10vw",
    minHeight: "10vw",
    maxHeight: "10vw",
    margin: "8px",
    backgroundColor: "rgba(0,0,0,0.8)",
    color: "white",
    fontSize: "11px",
    textAlign: "center",
    cursor: "pointer",
    transition: "all 0.1s",
    overflow: "hidden",
    borderRadius: "5px",
    userSelect: "none",
    position: "relative",

    "&:hover": {
      transform: "scale(1.05)",
      filter: "saturate(200%)",
    },

    "& p": {
      margin: "4px 4px",
      overflow: "hidden",
      width: "calc(100% - 8px)",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: "10px",
    },
  },
  imageWrapper: {
    width: "100%",
    height: "87%",
    borderRadius: "4px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    overflow: "hidden",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  new: {
    position: "absolute",
    top: "8px",
    left: "12px",
    fontSize: "11px",
    lineHeight: "11px",
    backgroundColor: "red",
    borderRadius: "5px",
    padding: "3px",
    paddingTop: "4px",
    color: "white",
    fontWeight: "bold",
    animationName: "$grow",
    animationDuration: "2s",
    animationIterationCount: "infinite",
  },
  openCarOverlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 1000,

    "& img": {
      maxWidth: "calc(100% - 60px)",
      maxHeight: "calc(100% - 60px)",
      margin: "50px",
    },

    "& p": {
      position: "fixed",
      bottom: "25px",
      width: "100vw",
      textAlign: "center",
      color: "white",
      fontSize: "12px",
    },
  },
  "@media (max-width: 950px)": {
    base: {
      "& h1": {
        fontSize: "40px",
        marginBottom: "16px",
      },
    },
    carContainer: {
      width: "calc(50% - 16px)",
      minWidth: "calc(50% - 16px)",
      maxWidth: "calc(50% - 16px)",
      height: "35vw",
      minHeight: "35vw",
      maxHeight: "35vw",
    },
    imageWrapper: {
      width: "100%",
      height: "87%",
    },
    content: {
      padding: "0px 0px",
      backgroundColor: "transparent",
    },
  },
});
