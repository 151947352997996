import React from 'react';
import { createTheming, createUseStyles } from 'react-jss';


// The default theme
const defaultTheme = {
  sideBar: {
    width: '80px',
  },
  intent: {
    primary: '#FFFFFF',
    error: '#FF2400',
    warning: '#F5E86A',
    info: '#0031d4',
  },
  colors: {
    primary: '#2a7ede',
    primaryLight: '#66adff',
    primaryDark: '#1c71d4',
    blue: '#0800f5',
    red: '#FF2400',
    yellow: '#F5E86A',
    green: '#B3DC5C',
    white: '#F8F9FA',
    grey1: '#B5C6D0',
    grey2: '#728596',
    grey3: '#495561',
    grey4: '#2C323C',
    grey5: '#27323E',
    greyField: '#3a4352',
    black: '#1F252E',
  },
  typo: {
    'title': {
      
    },
    'title-hero': {
      fontWeight: 600,
      fontSize: '64px',
      lineHeight: '66px',
    },
    'title-1': {
      fontWeight: 500,
      fontSize: '50px',
      lineHeight: '59px',
    },
    'title-2': {
      fontWeight: 500,
      fontSize: '38px',
      lineHeight: '56px',
    },
    'title-3': {
      fontWeight: 400,
      fontSize: '28px',
      lineHeight: '41px',
    },
    'title-4': {
      fontWeight: 400,
      fontSize: '21px',
      lineHeight: '31px',
    },
    'title-5': {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '23px',
    },
    'text': {
      fontWeight: 'inherit',
      fontSize: '14px',
      lineHeight: '21px',
    },
    'text-small': {
      fontWeight: 'inherit',
      fontSize: '12px',
      lineHeight: '18px',
    }
  }
}

const ThemeContext = React.createContext(defaultTheme);
const theming = createTheming(ThemeContext);
const { ThemeProvider, useTheme } = theming;

function useStyle(style, props) {
  const theme = useTheme();
  const useStyleFromJss = createUseStyles(style, { theming: theming });
  return useStyleFromJss({ ...props, theme }) || {};
}

export {
  ThemeProvider,
  ThemeContext,
  defaultTheme,
  createUseStyles,
  useStyle,
  useTheme,
}