import { Intent, FormGroup, InputGroup, TextArea } from "@blueprintjs/core";
import cn from "classnames";
import Icon from "../Icon";
import { useState, useStyle } from "utils/hooks";
import styles from "./styles";

const Input = (props) => {
  const {
    // FormGroup
    inline = false,
    label,
    labelInfo,
    labelFor,
    helperText,
    intent,
    className,
    // InputGroup
    defaultValue,
    value,
    placeholder,
    disabled,
    fill,
    inputRef,
    small,
    large,
    leftIcon,
    rightIcon,
    onLeftIconClick,
    onRightIconClick,
    onChange,
    type = "text",
    inputClassName,
    // Custom
    area = false,
    error,
    valid,
    // Rest
    ...rest
  } = props;
  const [show, setShow] = useState();
  const classes = useStyle(styles, props);
  const _intent = error ? Intent.DANGER : valid ? Intent.PRIMARY : intent;
  const _leftIcon = leftIcon ? (
    <div
      className={classes.iconWrapper}
      onClick={
        onLeftIconClick || type === "password" ? () => setShow(!show) : null
      }
    >
      <Icon
        name={leftIcon}
        className={classes.icon}
        color={error ? "red" : valid ? "primary" : "grey2"}
      />
    </div>
  ) : null;
  const _rightIcon = rightIcon ? (
    <div
      className={classes.iconWrapper}
      onClick={
        onRightIconClick || type === "password" ? () => setShow(!show) : null
      }
    >
      <Icon
        name={rightIcon}
        className={classes.icon}
        color={error ? "red" : valid ? "primary" : "grey2"}
      />
    </div>
  ) : null;
  return (
    <FormGroup
      inline={inline}
      label={label}
      labelInfo={labelInfo}
      labelFor={labelFor}
      intent={_intent}
      helperText={error || helperText}
      className={cn(className, classes.formGroup)}
    >
      {!area && (
        <InputGroup
          className={cn(inputClassName, classes.inputGroup)}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          fill={fill}
          inputRef={inputRef}
          small={small}
          large={large}
          leftElement={_leftIcon}
          rightElement={_rightIcon}
          onChange={onChange}
          type={type === "password" ? (show ? "text" : "password") : type}
          intent={_intent}
          error={error}
          {...(rest || {})}
        />
      )}
      {area && (
        <TextArea
          growVertically={false}
          className={cn(inputClassName, classes.inputGroup)}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          fill={fill}
          inputRef={inputRef}
          small={small}
          large={large}
          leftElement={_leftIcon}
          rightElement={_rightIcon}
          onChange={onChange}
          type={type === "password" ? (show ? "text" : "password") : type}
          intent={_intent}
          error={error}
          {...(rest || {})}
        />
      )}
    </FormGroup>
  );
};

export default Input;
