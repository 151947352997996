import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import store, { persistor } from 'store';
import "firebase/database";
import 'styles/index.css';

// Initialize Firebase


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      
      <App />

    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
); 
