export default (theme) => ({
  "@global": {
    ".react-datepicker__input-container input": {
      borderRadius: "8px !important",
      padding: "10px 15px !important",
      border: "none !important",
      boxShadow:
        "0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%), inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%) !important",
    },
  },
  formGroup: {
    "& label": {},
    "& textarea": {
      borderRadius: "8px",
      padding: "10px 20px",
      height: "auto",
      fontSize: "14px",
      lineHeight: "21px",
      color: "black",
      resize: "none",

      "::placeholder": {
        color: theme.colors.grey2,
      },
    },
  },
  inputGroup: {
    "& input": {
      borderRadius: "8px",
      padding: "10px 20px",
      height: "auto",
      fontSize: "14px",
      lineHeight: "21px",
      color: "black",

      "::placeholder": {
        color: theme.colors.grey2,
      },
    },
  },
  iconWrapper: {
    padding: "0px 20px",
  },
  icon: {
    margin: "0px",
    position: "absolute",
    top: "50%",
    right: "10px",
    transform: "translateY(-50%)",
  },
});
