import { Container, Row, Col } from 'components';
import { Link } from 'react-router-dom';
import routes from 'utils/routes';
import { useStyle, useHistory, useState } from 'utils/hooks';
import Emoji from 'a11y-react-emoji';
import styles from './styles';

export default function Navigation(props) {
  const classes = useStyle(styles, props);
  const history = useHistory();
  const [currentIndex, setCurrentIndex] = useState(0);
  const linkCycle = [
    <><Emoji symbol="🏁" label="racing" /> Tournoi Gran Turismo 7 <Emoji symbol="🏁" label="racing" style={{ paddingLeft: '5px' }} /></>,
    <>Woah là, c&rsquo;est pas encore ta fete</>,
    <>Heyye.. ca suffit</>,
    <>J&rsquo;ai dit NON!</>,
    <>Tabarnouche, on es-tu impatient par hasard?</>,
    <>Bon bon bon... va dans ta chambre</>,
    <>C&rsquo;est la dernière fois que je t&rsquo;avertis là...</>,
    <>Serieux?? Ok bye</>
  ]

  return (
    <header className={classes.base}>
      <Container>
        <Row align="center">
          <Col>
            <Row justify="center">
              <ul>       
                {/* <li onClick={() => history.push(routes.HOME)}>
                  <a>Accueil</a>
                </li> */}
                <li onClick={!props?.ready ? () => setCurrentIndex(currentIndex + 1) : () => history.push(routes.TOURNAMENT)}>
                  <a>{!props?.ready ? linkCycle[currentIndex] : linkCycle[0]}</a>
                </li>
              </ul>
            </Row>
          </Col>
        </Row>
      </Container>
      
      
    </header>
  )
} 