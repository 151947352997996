import React from "react";
import cn from "classnames";
import _ from "lodash";
import routes from "utils/routes";
import {
  useEffect,
  useState,
  useMemo,
  useHistory,
  useStyle,
} from "utils/hooks";
import { Navigation, Typo, Container, Input, Row, Icon, Col } from "components";
import Emoji from "a11y-react-emoji";
import { getSeededStyle } from "utils/helper";
import { Helmet } from "react-helmet";
import { cars } from "data";
import Layout from "components/Layout";
import styles from "./styles";

import firebase from "utils/firebase";
const db = firebase.database();

export default function Garage(props) {
  const classes = useStyle(styles, props);
  const [search, setSearch] = useState("");
  const [filteredCars, setFilteredCars] = useState(
    _.orderBy(cars, "new", "asc")
  );
  const [openCar, setOpenCar] = useState(null);
  const history = useHistory();

  const handleSearch = (value) => {
    setSearch(value);
    let _filtered = cars.filter((c) => {
      const v = (value || "").toUpperCase();
      const cv = (c?.name || "").toUpperCase();
      const cm = (c?.make || "").toUpperCase();
      return cv.includes(v) || cm.includes(v);
    });
    _filtered = _.orderBy(_filtered, "new", "asc");
    setFilteredCars(_filtered);
  };

  const openCarModal = (car) => {
    if (car) {
      setOpenCar(car);
    }
  };

  const closeCarModal = () => {
    setOpenCar(null);
  };

  let openCarUrl = require("assets/mystery-car.png")?.default;
  if (openCar) {
    try {
      openCarUrl = require(`assets/cars/${openCar}.png`)?.default;
    } catch (e) {
      console.log("error", e);
    }
  }

  return (
    <Layout className={classes.base}>
      <Helmet>
        <title>Garage</title>
      </Helmet>
      <Icon
        name="arrow-down"
        color="white"
        className={classes.backIcon}
        size={25}
        onClick={() => history.push(routes.TOURNAMENT)}
      />
      <h1>Garage</h1>
      <div className={classes.inputContainer}>
        <Input
          placeholder="Recherche..."
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <div className={classes.content}>
        {filteredCars?.map((car) => {
          let carUrl = require("assets/mystery-car.png")?.default;
          if (car) {
            try {
              carUrl = require(`assets/cars/${car?.name}.png`)?.default;
            } catch (e) {
              console.log("error", e);
            }
          }
          return (
            <div
              key={car?.name}
              className={classes.carContainer}
              onClick={() => openCarModal(car?.name)}
            >
              <div className={classes.imageWrapper}>
                <img src={carUrl} />
              </div>
              <p>{car?.name}</p>
              {car?.new && <div className={classes.new}>NEW</div>}
            </div>
          );
        })}
      </div>
      {openCar && openCarUrl && (
        <div className={classes.openCarOverlay} onClick={() => closeCarModal()}>
          <img src={openCarUrl} />
          <p>{openCar}</p>
        </div>
      )}
    </Layout>
  );
}
