import React from "react";
import cn from "classnames";
import routes from "utils/routes";
import {
  useEffect,
  useState,
  useMemo,
  useHistory,
  useStyle,
} from "utils/hooks";
import { Navigation, Typo, Container, Row, Icon, Col } from "components";
import Emoji from "a11y-react-emoji";
import { Helmet } from "react-helmet";
import { getSeededStyle } from "utils/helper";
import chrono from "assets/chrono.png";
import Layout from "components/Layout";
import styles from "./styles";

import firebase from "utils/firebase";
const db = firebase.database();

export default function Tournament(props) {
  const classes = useStyle(styles, props);
  const [showArchived, setShowArchived] = useState(false);
  const [championships, setChampionships] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const ref = db.ref("championships");

    ref.on("value", (snapshot) => {
      const ch = snapshot.val();
      const list = [];
      Object.keys(ch || {}).forEach((v) => {
        list.push({
          id: v,
          ...(ch[v] || {}),
        });
      });
      const reversed = [].concat(list).reverse();
      setChampionships(reversed);
    });

    return () => {
      ref.off();
    };
  }, []);

  const showDescription = (description) => {
    alert(description);
  };

  const laurelTotalWins =
    championships?.filter?.((c) => {
      const races = c?.races || [];
      const isDone = races?.length > 0 && !races.find((r) => !r.winner);
      const laurelWins =
        races.filter((r) => r.winner === "Laurel")?.length || 0;
      const reneWins = races.filter((r) => r.winner === "René")?.length || 0;

      return c.visible && isDone && laurelWins > reneWins;
    })?.length || 0;

  const reneTotalWins =
    championships?.filter?.((c) => {
      const races = c?.races || [];
      const isDone = races?.length > 0 && !races.find((r) => !r.winner);
      const laurelWins =
        races.filter((r) => r.winner === "Laurel")?.length || 0;
      const reneWins = races.filter((r) => r.winner === "René")?.length || 0;

      return c.visible && isDone && reneWins > laurelWins;
    })?.length || 0;

  const renderTile = (c, archived = false) => {
    const seededStyle = getSeededStyle(c?.name);
    const isDone = c?.races?.length > 0 && !c?.races.find((r) => !r.winner);
    const laurelWins =
      c?.races?.filter((r) => r.winner === "Laurel")?.length || 0;
    const reneWins = c?.races?.filter((r) => r.winner === "René")?.length || 0;
    const racesTotal = c?.races?.length;
    const racesDone = c?.races?.filter((r) => !!r.winner)?.length;

    let winner = null;
    if (isDone && laurelWins > reneWins) winner = "Laurel";
    if (isDone && reneWins > laurelWins) winner = "René";

    let racesLabel = c?.races?.length
      ? `${c?.races?.length} Course${c?.races?.length > 1 ? "s" : ""}`
      : "";
    if (racesDone > 0) {
      racesLabel = `${racesDone}/${c?.races?.length} Course${
        c?.races?.length > 1 ? "s" : ""
      }`;
    }
    if (c?.isTimeTrial) {
      racesLabel = "Time Trial";
    }
    if (winner && (racesDone === racesTotal || c?.isTimeTrial)) {
      racesLabel = (
        <>
          Victoire {winner} <Emoji symbol="🥇" label="party" />
        </>
      );
    }

    return (
      <div
        key={c?.id}
        className={cn(classes.championship, {
          [classes.archived]: archived,
          [classes.done]: isDone,
          [classes.timeTrial]: c?.isTimeTrial,
        })}
        onClick={() => history.push(routes.CHAMPIONSHIP_FUNC(c?.id))}
      >
        <div className={classes.topInfo}>
          <span style={{ textTransform: "capitalize" }}>
            {c?.name}
            {c?.date && !c?.isTimeTrial
              ? ` - ${new Date(c.date).toLocaleDateString()}`
              : ""}
          </span>
          <span>{racesLabel}</span>
        </div>
        <div
          className={classes.imageFill}
          style={{
            position: "relative",
            backgroundImage: `url(${
              require(`assets/wallpaper/${c?.imageIndex || 1}.jpg`).default
            })`,
          }}
        >
          <div
            className={classes.imageOverlay}
            style={{ backgroundColor: `${seededStyle.rgbMain}` }}
          />
          {isDone && (
            <>
              <img
                className={classes.winnerTrophy}
                src={require("assets/trophy.png").default}
              />
            </>
          )}
          {isDone && (
            <img
              className={classes.flagOverlay}
              src={require("assets/check-flag.png").default}
            />
          )}
        </div>
        {c?.description && (
          <Icon
            className={classes.descriptionIcon}
            size={30}
            name="info"
            color="black"
            onClick={(e) => {
              e?.stopPropagation();
              showDescription(c.description);
            }}
          />
        )}
        {c?.isTimeTrial && <img className={classes.chronoImage} src={chrono} />}
        {c?.date && c?.isTimeTrial && !c?.winner && (
          <p
            style={{
              color: "yellow",
              fontSize: "14px",
              marginTop: "16px",
              position: "absolute",
              bottom: "4px",
              padding: "6px 0 2px",
              margin: "0px",
              left: "15%",
              width: "65%",
              textAlign: "center",
              zIndex: 10,
              borderRadius: "10px",
              backgroundColor: "rgba(0,0,0,0.7)",
            }}
          >
            Se termine le {new Date(c.date).toLocaleDateString()} à 11:59pm
          </p>
        )}
      </div>
    );
  };

  const openGarage = () => {
    history.push(routes.GARAGE);
  };

  const unarchivedChampionships = useMemo(() => {
    return championships?.filter?.((c) => !c?.archived && c?.visible);
  }, [championships]);

  const archivedChampionships = useMemo(() => {
    return championships?.filter?.((c) => c?.archived && c?.visible);
  }, [championships]);

  return (
    <Layout className={classes.base}>
      <Helmet>
        <title>Championnats</title>
      </Helmet>
      <div className={classes.navbar}>
        <img
          onClick={() => history.push(routes.HOME)}
          className={classes.gtlogo}
          src={
            require("assets/logo_gt7.png").default
          } /*onClick={() => history.push(routes.HOME)}*/
        />
        <div className={classes.leaderboard}>
          <Icon
            className={classes.garageIcon}
            size={35}
            name="garage"
            color="white"
            onClick={() => openGarage()}
          />
          <div className={classes.leaderboardTrophy}>
            <Emoji symbol="🏆" label="Win" />
          </div>
          <p>
            <span>Laurel: </span>
            <div className={classes.totalWinLeaderboard}>{laurelTotalWins}</div>
          </p>
          <p>
            <span>René: </span>
            <div className={classes.totalWinLeaderboard}>{reneTotalWins}</div>
          </p>
        </div>
      </div>
      <h1>Championnats</h1>
      <div
        className={cn(classes.championshipList, {
          [classes.championshipListCentered]:
            unarchivedChampionships?.length <= 3,
        })}
      >
        {unarchivedChampionships?.map((c) => {
          return renderTile(c);
        })}
      </div>
      {archivedChampionships?.length > 0 && (
        <p
          className={classes.archiveLink}
          onClick={() => setShowArchived((s) => !s)}
        >
          {!showArchived ? "Afficher les archives" : "Fermer les archives"}
        </p>
      )}
      {showArchived && (
        <div
          className={cn(classes.championshipList, {
            [classes.championshipListCentered]:
              archivedChampionships?.length <= 3,
          })}
        >
          {archivedChampionships?.map((c) => {
            return renderTile(c, true);
          })}
        </div>
      )}
      <p className={classes.signature}>
        Créé pour René, de son fils Laurel. 2022
      </p>
      <p
        onClick={() => history.push(routes.ADMIN)}
        className={classes.adminPanel}
      >
        Admin Panel
      </p>
    </Layout>
  );
}
