import React, { useState, useEffect, useRef, useCallback } from "react";
import cn from "classnames";
import { useStyle } from "utils/hooks";
import { Navigation } from "components";
import useWindowSize from "react-use/lib/useWindowSize";
import Emoji from "a11y-react-emoji";
import Confetti from "react-confetti";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";
import { Button} from 'components';
import champagne from "assets/champagne.gif";
import birthdaySong from "assets/birthday-song.mp3";
import styles from "./styles";

export default function Birthday2023(props) {
  const classes = useStyle(styles, props);
  const [started, setStarted] = useState(false);
  const [soundPlayed, setSoundPlayed] = useState(false);
  const { width, height } = useWindowSize();
  const audioRef = useRef();

  const play = useCallback(async () => {
    audioRef.current = new Audio(birthdaySong);
    audioRef.current.volume = 0.5;
    await audioRef.current.play();
    setSoundPlayed(true);
  }, [soundPlayed])

  useEffect(() => {
    play();
  }, [started])

  if (!started) {
    return (
      <Layout className={classes.base}>
         <Button className={classes.button} onClick={() => setStarted(true)} intent="info">
            Clique ici pour commencer ta fête
          </Button>
      </Layout>
    )
  }

  return (
    <Layout className={classes.base}>
      <Helmet><title>Bonne fête 2023!</title></Helmet>
      <div className={classes.header}>
        <h1>BONNE FËTE 2023!</h1>
      </div>
      <div className={classes.body}>
      <h2>STEAK & RACE</h2>
        <h2>La 63ème édition de &quot;Fête à Papa&quot;, sponsorisé par:</h2>
        <img
          className={classes.gibbyslogo}
          src={
            require("assets/gibbys-logo-2.png").default
          }
        />
        <br/>
        <br/>
        <br/>
        <p>
          Pour ta fête incroyable de 2023, afin de célébrer tes 31 ans, nous avons un agenda bien spécial! 
          Nous passerons une soirée inoubliable père-fils.
        </p>
        <br/>
        <h2>🥩 Le Ravitaillement 🥩</h2>
        <p>Le jour venu, tu viendras me rejoindre au vieux-port afin de commencer la soirée en force chez <b>Gibby&lsquo;s</b>. Nous aurons un repas carnivore en jasant de la météo et de la table collante. Je vais m&lsquo;assurer que notre table ne soit pas sous un conduit d&lsquo;air climatisé afin d&lsquo;assurer ton comfort absolu. Nous dégusterons de la bonne bière, entrées, repas principal, et un dessert (si tu fais ta chambre).</p>
        <br/>
        <h2>🏁 Le Championnat 🏁</h2>
        <p>Lorsque nous aurons fini notre repas, nous prendrons une belle marche de santé d&lsquo;environ 45 secondes afin de revenir à ma demeure. Nous y trouverons un assortiment incroyable de brevages, snacks, chips, ainsi qu&lsquo;un setup spécial à 2 écrans qui nous permettra de passer la soirée à courser père-fils. Il n&lsquo;y a pas d&lsquo;heure limite donc soit prêt a courser jusqu&lsquo;aux petites heures</p>
        <br/><br/>
        <h2>Ce que tu dois emmener:</h2>
        <p>- Ta Playstation 4 + fils (pour jouer sur 2 écrans)</p>
        <p>- Un bedon vide pour bien le remplir de steak</p>
        <p>- Ton beau sourire scintillant et joie de vivre</p>
        <br/>
        <br/>
        <h3 style={{ color: '#800020'}}>* DATE À DÉTERMINER ENSEMBLE *</h3>
      </div>
      <img
          className={classes.gibbyslogo2}
          src={
            require("assets/gibbys-logo.png").default
          }
        />
        <p style={{ color: 'white', margin: 0}}>En partenariat avec Gibby&apos;s:</p>
        <p style={{ color: 'white'}}>&#34;Si tu veux OVERTAKE, mange un gros STEAK&#34; ™</p>
      <img className={classes.ch1} src={champagne} />
      <img className={classes.ch2} src={champagne} />
      <Confetti width={width} height={height} numberOfPieces={50} />
    </Layout>
  );
}
