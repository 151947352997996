export const tracks = [
  // Americas
  {
    name: "Autodrómo de Interlagos",
    location: "Americas",
    flag: "🇧🇷",
    km: 4.309,
  },
  {
    name: "Blue Moon Bay Speedway",
    location: "Americas",
    flag: "🇺🇸",
    km: 3.22,
  },
  {
    name: "Michelin Raceway Road Atlanta",
    location: "Americas",
    flag: "🇺🇸",
    km: 4.088,
  },
  {
    name: "Colorado Springs – Lake",
    location: "Americas",
    flag: "🇺🇸",
    km: 2.99,
  },
  {
    name: "Daytona International Speedway",
    location: "Americas",
    flag: "🇺🇸",
    km: 4,
  },
  {
    name: "Daytona Superspeedway",
    location: "Americas",
    flag: "🇺🇸",
    km: 5.7293,
  },
  { name: "Daytona Road Course", location: "Americas", flag: "🇺🇸", km: 5.7293 },
  { name: "Fisherman’s Ranch", location: "Americas", flag: "🇺🇸", km: 6.893 },
  { name: "Northern Isle Speedway", location: "Americas", flag: "🇺🇸", km: 0.9 },
  {
    name: "Trial Mountain Circuit",
    location: "Americas",
    flag: "🇺🇸",
    km: 3.98,
  },
  {
    name: "Special Stage Route X",
    location: "Americas",
    flag: "🇺🇸",
    km: 30.283,
  },
  {
    name: "Watkins Glen International Short Course",
    location: "Americas",
    flag: "🇺🇸",
    km: 3.943,
  },
  {
    name: "Watkins Glen International Long Course",
    location: "Americas",
    flag: "🇺🇸",
    km: 5.435,
  },
  {
    name: "WeatherTech Raceway Laguna Seca",
    location: "Americas",
    flag: "🇺🇸",
    km: 3.058,
  },
  {
    name: "Willow Springs International Raceway",
    location: "Americas",
    flag: "🇺🇸",
    km: 4.023,
  },
  {
    name: "Willow Springs International Raceway – Big Willow",
    location: "Americas",
    flag: "🇺🇸",
    km: 4.023,
  },
  {
    name: "Grand Valley",
    location: "Americas",
    flag: "🇺🇸",
    km: 5.023,
  },
  {
    name: "Grand Valley - Highway 1",
    location: "Americas",
    flag: "🇺🇸",
    km: 5.023,
  },
  {
    name: "Grand Valley - South",
    location: "Americas",
    flag: "🇺🇸",
    km: 3.023,
  },
  // Asia
  {
    name: "Autopolis International Racing Course",
    location: "Asia",
    flag: "🇯🇵",
    km: 4.673,
  },
  { name: "Broad Bean Raceway", location: "Asia", flag: "🇯🇵", km: 1.665 },
  {
    name: "Fuji International Speedway",
    location: "Asia",
    flag: "🇯🇵",
    km: 4.563,
  },
  { name: "High Speed Ring", location: "Asia", flag: "🇯🇵", km: 3.1 },
  { name: "Kyoto Driving Park", location: "Asia", flag: "🇯🇵", km: 4.912 },
  {
    name: "Kyoto Driving Park – Miyabi",
    location: "Asia",
    flag: "🇯🇵",
    km: 1.953,
  },
  {
    name: "Kyoto Driving Park – Yamagiwa & Miyabi",
    location: "Asia",
    flag: "🇯🇵",
    km: 6.846,
  },
  {
    name: "Kyoto Driving Park – Yamagiwa",
    location: "Asia",
    flag: "🇯🇵",
    km: 4.912,
  },
  { name: "Suzuka Circuit", location: "Asia", flag: "🇯🇵", km: 5.807 },
  { name: "Suzuka Circuit East", location: "Asia", flag: "🇯🇵", km: 2.24 },
  { name: "Tokyo Expressway", location: "Asia", flag: "🇯🇵", km: 7.301 },
  {
    name: "Tokyo Expressway – Central Inner Loop",
    location: "Asia",
    flag: "🇯🇵",
    km: 4.369,
  },
  {
    name: "Tokyo Expressway – Central Outer Loop",
    location: "Asia",
    flag: "🇯🇵",
    km: 4.405,
  },
  {
    name: "Tokyo Expressway – East Inner Loop",
    location: "Asia",
    flag: "🇯🇵",
    km: 7.192,
  },
  {
    name: "Tokyo Expressway – East Outer Loop",
    location: "Asia",
    flag: "🇯🇵",
    km: 7.301,
  },
  {
    name: "Tokyo Expressway – South Inner Loop",
    location: "Asia",
    flag: "🇯🇵",
    km: 6.56,
  },
  {
    name: "Tokyo Expressway – South Outer Loop",
    location: "Asia",
    flag: "🇯🇵",
    km: 5.201,
  },
  { name: "Tsukuba Circuit", location: "Asia", flag: "🇯🇵", km: 2.04 },
  // Europe
  { name: "Alsace – Village", location: "Europe", flag: "🇫🇷", km: 5.423 },
  {
    name: "Autodrome Lago Maggiore",
    location: "Europe",
    flag: "🇮🇹",
    km: 5.809,
  },
  {
    name: "Autodromo Nazionale Monza",
    location: "Europe",
    flag: "🇮🇹",
    km: 5.793,
  },
  { name: "Brands Hatch", location: "Europe", flag: "🇬🇧", km: 3.916 },
  {
    name: "Circuit de Barcelona-Catalunya Grand Prix",
    location: "Europe",
    flag: "🇪🇸",
    km: 4.675,
  },
  {
    name: "Circuit de Barcelona-Catalunya Grand Prix - No Chicane",
    location: "Europe",
    flag: "🇪🇸",
    km: 4.675,
  },
  {
    name: "Circuit de Barcelona-Catalunya - National Layout",
    location: "Europe",
    flag: "🇪🇸",
    km: 4.675,
  },
  {
    name: "Circuit de Barcelona-Catalunya - Rallycross Layout",
    location: "Europe",
    flag: "🇪🇸",
    km: 4.675,
  },
  {
    name: "Circuit de la Sarthe",
    location: "Europe",
    flag: "🇫🇷",
    km: 13.626,
  },
  {
    name: "Circuit de Sainte-Croix",
    location: "Europe",
    flag: "🇫🇷",
    km: 10.825,
  },
  {
    name: "Circuit de Sainte-Croix – A",
    location: "Europe",
    flag: "🇫🇷",
    km: 9.477,
  },
  {
    name: "Circuit de Sainte-Croix – B",
    location: "Europe",
    flag: "🇫🇷",
    km: 7.062,
  },
  {
    name: "Circuit de Sainte-Croix – C",
    location: "Europe",
    flag: "🇫🇷",
    km: 10.825,
  },
  {
    name: "Circuit de Spa-Francorchamps",
    location: "Europe",
    flag: "🇧🇪",
    km: 7.004,
  },
  {
    name: "Circuit de Spa-Francorchamps - 24h",
    location: "Europe",
    flag: "🇧🇪",
    km: 7.004,
  },
  { name: "Deep Forest Raceway", location: "Europe", flag: "🇨🇭", km: 3.6 },
  { name: "Dragon Trail", location: "Europe", flag: "🇭🇷", km: 5.209 },
  { name: "Dragon Trail – Gardens", location: "Europe", flag: "🇭🇷", km: 4.352 },
  { name: "Dragon Trail – Seaside", location: "Europe", flag: "🇭🇷", km: 5.209 },
  { name: "Goodwood Motor Circuit", location: "Europe", flag: "🏴󠁧󠁢󠁥󠁮󠁧󠁿", km: 3.809 },
  { name: "Nurburgring", location: "Europe", flag: "🇩🇪", km: 20.831 },
  {
    name: "Nurburgring - 24h",
    location: "Europe",
    flag: "🇩🇪",
    km: 25.378,
  },
  {
    name: "Nurburgring - Endurance",
    location: "Europe",
    flag: "🇩🇪",
    km: 25.378,
  },
  { name: "Nurburgring - Sprint", location: "Europe", flag: "🇩🇪", km: 3.629 },
  { name: "Red Bull Ring", location: "Europe", flag: "🇦🇹", km: 4.318 },
  { name: "Sardegna", location: "Europe", flag: "🇪🇺", km: 5.113 },
  {
    name: "Sardegna – Road Track A",
    location: "Europe",
    flag: "🇪🇺",
    km: 5.113,
  },
  {
    name: "Sardegna – Road Track B",
    location: "Europe",
    flag: "🇪🇺",
    km: 3.893,
  },
  {
    name: "Sardegna – Road Track C",
    location: "Europe",
    flag: "🇪🇺",
    km: 2.661,
  },
  { name: "Sardegna – Windmills", location: "Europe", flag: "🇪🇺", km: 3.31 },
  // Oceanic
  { name: "Mount Panorama", location: "Oceanic", flag: "🇦🇺", km: 6.213 },
];

export const tracksOptions = () => {
  return tracks.map((track) => {
    return {
      label: track.name,
      value: track.name,
    };
  });
};

export const cars = [
  // Abarth
  { name: "Abarth 500 '09", make: "Abarth" },
  { name: "Abarth 595 SS '70", make: "Abarth" },
  { name: "Abarth 695 SS", make: "Abarth" },
  { name: "Abarth 1500 Biposto Bertone B.A.T 1 '52", make: "Abarth" },
  // Alfa Romeo
  { name: "Alfa Romeo 4C Gr.3", make: "Alfa Romeo" },
  { name: "Alfa Romeo 4C Gr.3 Road Car", make: "Alfa Romeo" },
  { name: "Alfa Romeo 4C Gr.4", make: "Alfa Romeo" },
  { name: "Alfa Romeo 4C Launch Edition '14", make: "Alfa Romeo" },
  { name: "Alfa Romeo 8C 2900", make: "Alfa Romeo" },
  { name: "Alfa Romeo 8C Competizione '08", make: "Alfa Romeo" },
  { name: "Alfa Romeo 155 2.5 V6 TI '93", make: "Alfa Romeo" },
  { name: "Alfa Romeo Giulia GTAm '20", make: "Alfa Romeo" },
  {
    name: "Alfa Romeo GIULIA TZ2 carrozzata da ZAGATO '65",
    make: "Alfa Romeo",
  },
  { name: "Alfa Romeo MiTo 1.4 T Sport '09", make: "Alfa Romeo" },
  {
    name: "Alfa Romeo Giulia Sprint GT Veloce '67",
    make: "Alfa Romeo",
  },
  // Alpine
  { name: "Alpine A110 1600S '72", make: "Alpine" },
  { name: "Alpine A110 Première Édition '17", make: "Alpine" },
  { name: "Alpine A220 Race Car '68", make: "Alpine" },
  { name: "Alpine Vision Gran Turismo", make: "Alpine" },
  { name: "Alpine Vision Gran Turismo 2017", make: "Alpine" },
  { name: "Alpine Vision Gran Turismo Race Mode", make: "Alpine" },
  // AMG
  { name: "AMG Mercedes CLK-LM '98", make: "AMG" },
  // Amuse
  { name: "Amuse NISMO 380RS Super Leggera", make: "Amuse" },
  { name: "Amuse S2000 GT1 Turbo", make: "Amuse" },
  // Aston Martin
  { name: "Aston Martin DB3S CN.1 '53", make: "Aston Martin" },
  { name: "Aston Martin DB5 '64", make: "Aston Martin" },
  { name: "Aston Martin DB11 '16", make: "Aston Martin" },
  { name: "Aston Martin DBR9 GT1 '10", make: "Aston Martin" },
  { name: "Aston Martin DP-100 Vision Gran Turismo", make: "Aston Martin" },
  { name: "Aston Martin One-77 '11", make: "Aston Martin" },
  { name: "Aston Martin V8 Vantage S '15", make: "Aston Martin" },
  { name: "Aston Martin V12 Vantage GT3 '12", make: "Aston Martin" },
  { name: "Aston Martin Vantage Gr.4", make: "Aston Martin" },
  { name: "Aston Martin Vulcan '16", make: "Aston Martin" },
  { name: "Aston Martin Valkyrie '21", make: "Aston Martin", new: true },
  // Audi
  { name: "Audi e-tron Vision Gran Turismo", make: "Audi" },
  { name: "Audi R8 4.2 FSI R tronic '07", make: "Audi" },
  { name: "Audi R8 LMS (Audi Sport Team WRT) '15", make: "Audi" },
  { name: "Audi R8 LMS Evo", make: "Audi" },
  { name: "Audi R18 TDI (Audi Sport Team Joest) '11", make: "Audi" },
  { name: "Audi R18 TDI (Le Mans 2011)", make: "Audi" },
  { name: "Audi R18 (Audi Sport Team Joest) '16", make: "Audi" },
  { name: "Audi Sport quattro S1 Pikes Peak '87", make: "Audi" },
  { name: "Audi TT Coupe 3.2 quattro '03", make: "Audi" },
  { name: "Audi TT Cup '16", make: "Audi" },
  { name: "Audi TTS Coupe '14", make: "Audi" },
  { name: "Audi Vision Gran Turismo", make: "Audi" },
  { name: "Audi RS 5 Turbo DTM '19", make: "Audi" },
  // Autobianchi
  { name: "Autobianchi A112 Abarth '85", make: "Autobianchi" },
  // BAC
  { name: "BAC Mono", make: "BAC" },
  // BMW
  { name: "BMW 3.0 CSL '71", make: "BMW" },
  { name: "BMW 3.0 CSL '73", make: "BMW" },
  { name: "BMW i3 '15", make: "BMW" },
  { name: "BMW M2 Competition '18", make: "BMW" },
  { name: "BMW M3 '89", make: "BMW" },
  { name: "BMW M3 Coupé '03", make: "BMW" },
  { name: "BMW M3 Coupé '07", make: "BMW" },
  { name: "BMW M3 GT (BMW Motorsport) '11", make: "BMW" },
  { name: "BMW M3 Sport Evolution '89", make: "BMW" },
  { name: "BMW M4 Coupé '14", make: "BMW" },
  { name: "BMW M4 Gr.4", make: "BMW" },
  { name: "BMW M4 Safety Car", make: "BMW" },
  { name: "BMW M6 GT3 Endurance Model '16", make: "BMW" },
  { name: "BMW M6 GT3 Sprint Model '16", make: "BMW" },
  { name: "BMW McLaren F1 GTR Race Car '97", make: "BMW" },
  { name: "BMW Vision Gran Turismo", make: "BMW" },
  { name: "BMW Z4 GT3 '11", make: "BMW" },
  { name: "BMW Z8 '01", make: "BMW" },
  // Bugatti
  { name: "Bugatti Veyron 16.4 '13", make: "Bugatti" },
  { name: "Bugatti Veyron Gr.4", make: "Bugatti" },
  { name: "Bugatti Vision Gran Turismo", make: "Bugatti" },
  { name: "Bugatti Vision Gran Turismo Gr.1", make: "Bugatti" },
  { name: "Bugatti Chiron '16", make: "Bugatti" },
  // Chaparral
  { name: "Chaparral 2J '70", make: "Chaparral" },
  { name: "Chevrolet Chaparral 2X Vision Gran Turismo", make: "Chaparral" },
  // Chevrolet
  { name: "Chevrolet Camaro SS '16", make: "Chevrolet" },
  { name: "Chevrolet Camaro Z28 302 '69", make: "Chevrolet" },
  { name: "Chevrolet Camaro ZL1 1LE Package '18", make: "Chevrolet" },
  { name: "Chevrolet Corvette C7 Gr.3", make: "Chevrolet" },
  { name: "Chevrolet Corvette C7 Gr.3 Road Car", make: "Chevrolet" },
  { name: "Chevrolet Corvette C7 Gr.4", make: "Chevrolet" },
  { name: "Chevrolet Corvette C7 ZR1 '19", make: "Chevrolet" },
  {
    name: "Chevrolet Corvette Sting Ray Sport Coupe (C2) '63",
    make: "Chevrolet",
  },
  { name: "Chevrolet Corvette Stingray (C7) '14", make: "Chevrolet" },
  {
    name: "Chevrolet Corvette Stingray Convertible (C3) '69",
    make: "Chevrolet",
  },
  { name: "Chevrolet Corvette Stingray L46 350 (C3) '69", make: "Chevrolet" },
  { name: "Chevrolet Corvette StingRay Racer Concept '59", make: "Chevrolet" },
  { name: "Chevrolet Corvette ZR-1 (C4) '90", make: "Chevrolet" },
  { name: "Chevrolet Corvette ZR1 (C6) '09", make: "Chevrolet" },
  { name: "Chevrolet Corvette C8 Stingray '20", make: "Chevrolet" },
  { name: "Chevrolet Corvette (C1) '58", make: "Chevrolet", new: true },
  // Chris Holstrom Concepts
  {
    name: "Chris Holstrom Concepts 1967 Chevy Nova",
    make: "Chris Holstrom Concepts",
  },
  // Citroën
  { name: "GT by Citroën Gr.4", make: "Citroën" },
  { name: "GT by Citroën Race Car Gr.3", make: "Citroën" },
  { name: "GT by Citroën Road Car", make: "Citroën" },
  { name: "Citroën DS3 Racing '11", make: "Citroën" },
  { name: "Citroën DS 21 Pallas '70", make: "Citroën" },
  // Daihatsu
  { name: "Daihatsu Copen Active Top '02", make: "Daihatsu" },
  { name: "DAIHATSU COPEN RJ Vision Gran Turismo", make: "Daihatsu" },
  // De Tomaso
  { name: "De Tomaso Mangusta '69", make: "De Tomaso" },
  { name: "De Tomaso Pantera '71", make: "De Tomaso" },
  { name: "De Tomaso Mangusta (Christian Dior)", make: "De Tomaso" },
  // DMC
  { name: "DMC DeLorean S2 '04", make: "DMC" },
  // Dodge
  { name: "Dodge Challenger RT '70", make: "Dodge" },
  { name: "Dodge Charger SRT Hellcat '15", make: "Dodge" },
  { name: "Dodge Charger SRT Hellcat Safety Car", make: "Dodge" },
  { name: "Dodge Super Bee '70", make: "Dodge" },
  { name: "Dodge Viper Gr.4", make: "Dodge" },
  { name: "Dodge Viper GTS '02", make: "Dodge" },
  { name: "Dodge Viper GTS '13", make: "Dodge" },
  { name: "Dodge Viper SRT GT3-R '15", make: "Dodge" },
  { name: "Dodge Viper SRT-10 Coupe '06", make: "Dodge" },
  { name: "SRT Tomahawk GTS-R Vision Gran Turismo", make: "Dodge" },
  { name: "SRT Tomahawk S Vision Gran Turismo", make: "Dodge" },
  { name: "SRT Tomahawk Vision Gran Turismo Gr.1", make: "Dodge" },
  { name: "SRT Tomahawk X Vision Gran Turismo", make: "Dodge" },
  // Eckerts Rod & Custom
  { name: "Eckerts Rod & Custom Mach Forty", make: "Eckerts Rod & Custom" },
  // Ferrari
  {
    name: "Ferrari 250 GT Berlinetta passo corto CN.2521 '61",
    make: "Ferrari",
  },
  { name: "Ferrari 250 GTO CN.3729GT '62", make: "Ferrari" },
  { name: "Ferrari 308 GTB", make: "Ferrari" },
  { name: "Ferrari 330 P4 '67", make: "Ferrari" },
  { name: "Ferrari 365 GTB4 '71", make: "Ferrari" },
  { name: "Ferrari 458 Italia '09", make: "Ferrari" },
  { name: "Ferrari 458 Italia GT3 '13", make: "Ferrari" },
  { name: "Ferrari 458 Italia Gr.4", make: "Ferrari" },
  {
    name: "Ferrari 500 Mondial Pininfarina Coupe CN.0422MD '54",
    make: "Ferrari",
  },
  { name: "Ferrari 512BB '76", make: "Ferrari" },
  { name: "Ferrari Dino 246 GT '71", make: "Ferrari" },
  { name: "Enzo Ferrari '02", make: "Ferrari" },
  { name: "Ferrari F8 Tributo '19", make: "Ferrari" },
  { name: "Ferrari F12berlinetta '12", make: "Ferrari" },
  { name: "Ferrari F40 '92", make: "Ferrari" },
  { name: "Ferrari F50 '95", make: "Ferrari" },
  { name: "Ferrari F430 '06", make: "Ferrari" },
  { name: "Ferrari FXX K '15", make: "Ferrari" },
  { name: "Ferrari GTO '84", make: "Ferrari" },
  { name: "Ferrari LaFerrari '13", make: "Ferrari" },
  { name: "Ferrari Testarossa '91", make: "Ferrari" },
  { name: "Ferrari Vision Gran Turismo", make: "Ferrari" },
  // Fiat
  { name: "Fiat 500 F '68", make: "Fiat" },
  { name: "Fiat 500 1.2 8V Lounge SS '08", make: "Fiat" },
  // Ford
  { name: "1932 Ford Roadster ‘63", make: "Ford" },
  { name: "Ford F-150 SVT Raptor '11", make: "Ford" },
  { name: "Ford Focus Gr.B Rally Car", make: "Ford" },
  { name: "Ford Focus RS '18", make: "Ford" },
  { name: "Ford Focus ST '15", make: "Ford" },
  { name: "Ford GT '06", make: "Ford" },
  { name: "Ford GT '17", make: "Ford" },
  { name: "Ford GT LM Race Car Spec II", make: "Ford" },
  { name: "Ford GT LM Spec II Test Car", make: "Ford" },
  { name: "Ford GT Race Car '18", make: "Ford" },
  { name: "Ford GT40 '66", make: "Ford" },
  { name: "Ford Mark IV Race Car '67", make: "Ford" },
  { name: "Ford Mustang Boss 429 '69", make: "Ford" },
  { name: "Ford Mustang Gr.3", make: "Ford" },
  { name: "Ford Mustang Gr.3 Road Car", make: "Ford" },
  { name: "Ford Mustang Gr.4", make: "Ford" },
  { name: "Ford Mustang Gr.B Rally Car", make: "Ford" },
  { name: "Ford Mustang GT Premium Fastback '15", make: "Ford" },
  { name: "Ford Mustang Mach 1 '71", make: "Ford" },
  { name: "Ford Shelby GT350R '16", make: "Ford" },
  { name: "Ford Sierra RS 500 Cosworth '87", make: "Ford" },
  // Gran Turismo
  { name: "Gran Turismo F1500T-A", make: "Gran Turismo" },
  { name: "Gran Turismo RACING KART 125 Shifter", make: "Gran Turismo" },
  { name: "Red Bull X2014 Junior", make: "Gran Turismo" },
  { name: "Red Bull X2014 Standard Car", make: "Gran Turismo" },
  { name: "Red Bull X2019 Competition", make: "Gran Turismo" },
  {
    name: "Gran Turismo Red Bull X2019 25th Anniversary",
    make: "Gran Turismo",
  },
  // Genesis
  { name: "Genesis G70 3.3T AWD Prestige Package '22", make: "Genesis" },
  { name: "Genesis G70 GR4", make: "Genesis" },
  { name: "Genesis X GR3", make: "Genesis" },
  // GReddy
  { name: "GReddy Fugu Z", make: "GReddy" },
  // Greening Auto Company
  {
    name: "Greening Auto Company Maverick",
    make: "Greening Auto Company",
  },
  // Honda
  { name: "Honda BEAT '91", make: "Honda" },
  { name: "Honda CIVIC TYPE R (EK) '97", make: "Honda" },
  { name: "Honda CIVIC TYPE R (EK) '98", make: "Honda" },
  { name: "Honda CIVIC TYPE R (FK2) '15", make: "Honda" },
  { name: "Honda Civic Type R Limited Edition (FK8) '20", make: "Honda" },
  { name: "Honda EPSON NSX '08", make: "Honda" },
  { name: "Honda Fit Hybrid '14", make: "Honda" },
  { name: "Honda INTEGRA TYPE R (DC2) '95", make: "Honda" },
  { name: "Honda INTEGRA TYPE R (DC2) '98", make: "Honda" },
  { name: "Honda NSX '17", make: "Honda" },
  { name: "Honda NSX Gr.3", make: "Honda" },
  { name: "Honda NSX Gr.4", make: "Honda" },
  { name: "Honda NSX Gr.B Rally Car", make: "Honda" },
  { name: "Honda NSX Type R '02", make: "Honda" },
  { name: "Honda NSX Type R '92", make: "Honda" },
  { name: "Honda Project 2&4 powered by RC213V", make: "Honda" },
  { name: "Honda RAYBRIG NSX CONCEPT-GT '16", make: "Honda" },
  { name: "Honda S660 '15", make: "Honda" },
  { name: "Honda S800 '66", make: "Honda" },
  { name: "Honda S2000 '99", make: "Honda" },
  { name: "Honda Sports Vision Gran Turismo", make: "Honda" },
  { name: "Honda RA272 ’65", make: "Honda" },
  { name: "Dallara SF19 Super Formula - Honda '19", make: "Honda" },
  { name: "Dallara SF23 Super Formula - Honda '23", make: "Toyota" },
  // Hyundai
  { name: "Hyundai Genesis Coupe 3.8 Track '13", make: "Hyundai" },
  { name: "Hyundai Genesis Gr.3", make: "Hyundai" },
  { name: "Hyundai Genesis Gr.4", make: "Hyundai" },
  { name: "Hyundai Genesis Gr.B Rally Car", make: "Hyundai" },
  { name: "Hyundai N 2025 Vision Gran Turismo", make: "Hyundai" },
  { name: "Hyundai N 2025 Vision Gran Turismo Gr.1", make: "Hyundai" },
  // Infiniti
  { name: "Infiniti CONCEPT Vision Gran Turismo", make: "Infiniti" },
  // Italdesign
  {
    name: "Italdesign EXENEO Vision Gran Turismo Street Mode",
    make: "Italdesign",
  },
  {
    name: "Italdesign EXENEO Vision Gran Turismo Off-road Mode",
    make: "Italdesign",
  },
  // Jaguar
  { name: "Jaguar D-type '54", make: "Jaguar" },
  { name: "Jaguar E-TYPE Coupe '61", make: "Jaguar" },
  { name: "Jaguar F-type Gr.3", make: "Jaguar" },
  { name: "Jaguar F-type Gr.4", make: "Jaguar" },
  { name: "Jaguar F-type R Coupe '14", make: "Jaguar" },
  { name: "Jaguar Vision Gran Turismo Coupé", make: "Jaguar" },
  { name: "Jaguar Vision Gran Turismo Roadster", make: "Jaguar" },
  { name: "Jaguar Vision Gran Turismo SV", make: "Jaguar" },
  { name: "Jaguar XJ13 '66", make: "Jaguar" },
  { name: "Jaguar XJR-9 '88", make: "Jaguar" },
  { name: "Jaguar XJ220 '92", make: "Jaguar" },
  // Jeep
  { name: "Jeep Willys MB '45", make: "Jeep" },
  // KTM
  { name: "KTM X-BOW R '12", make: "KTM" },
  // Lamborghini
  { name: "Lamborghini Aventador LP700-4 '11", make: "Lamborghini" },
  {
    name: "Lamborghini Aventador LP 750-4 Superveloce '15",
    make: "Lamborghini",
  },
  { name: "Lamborghini Countach 25th Anniversary '88", make: "Lamborghini" },
  { name: "Lamborghini Countach LP400 '74", make: "Lamborghini" },
  { name: "Lamborghini Diablo GT '00", make: "Lamborghini" },
  { name: "Lamborghini Lambo V12 Vision Gran Turismo", make: "Lamborghini" },
  { name: "Lamborghini Huracán Gr.4", make: "Lamborghini" },
  { name: "Lamborghini Huracán GT3 '15", make: "Lamborghini" },
  { name: "Lamborghini Huracán LP 610-4 '15", make: "Lamborghini" },
  {
    name: "Lamborghini Miura P400 Bertone Prototype CN.0706 '67",
    make: "Lamborghini",
  },
  { name: "Lamborghini Murcielago LP640 '09", make: "Lamborghini" },
  { name: "Lamborghini Veneno '14", make: "Lamborghini" },
  // Lancia
  { name: "Lancia DELTA HF Integrale Evoluzione '91", make: "Lancia" },
  { name: "Lancia STRATOS '73", make: "Lancia" },
  // Lexus
  { name: "Lexus au TOM'S RC F '16", make: "Lexus" },
  { name: "Lexus LC500 '17", make: "Lexus" },
  { name: "Lexus LF-LC GT 'Vision Gran Turismo'", make: "Lexus" },
  { name: "Lexus PETRONAS TOM'S SC430 '08", make: "Lexus" },
  { name: "Lexus RC F '14", make: "Lexus" },
  { name: "Lexus RC F Gr.4", make: "Lexus" },
  { name: "Lexus RC F GT3 (Emil Frey Racing) '17", make: "Lexus" },
  { name: "Lexus RC F GT3 prototype (Emil Frey Racing) '16", make: "Lexus" },
  // Maserati
  { name: "Maserati A6GCS53 Spyder '54", make: "Maserati" },
  { name: "Maserati GranTurismo S '08", make: "Maserati" },
  { name: "Maserati Merak SS ’80", make: "Maserati" },
  { name: "Maserati MC20 '20", make: "Maserati", new: true },
  // Mazda
  { name: "Mazda 787B '91", make: "Mazda" },
  { name: "Mazda Atenza Gr.3", make: "Mazda" },
  { name: "Mazda Atenza Gr.3 Road Car", make: "Mazda" },
  { name: "Mazda Atenza Gr.4", make: "Mazda" },
  { name: "Mazda Atenza Sedan XD L Package '15", make: "Mazda" },
  { name: "Mazda Demio XD Touring '15", make: "Mazda" },
  { name: "Mazda Eunos Roadster (NA Special Package) '89", make: "Mazda" },
  { name: "Mazda Roadster NR-A (ND) '22", make: "Mazda" },
  { name: "Mazda LM55 Vision Gran Turismo", make: "Mazda" },
  { name: "Mazda LM55 Vision Gran Turismo Gr.1", make: "Mazda" },
  { name: "Mazda Roadster S (ND) '15", make: "Mazda" },
  { name: "Mazda Roadster Touring Car", make: "Mazda" },
  { name: "Mazda RX-7 GT-X (FC) '90", make: "Mazda" },
  { name: "Mazda RX-7 Spirit R Type A (FD) '02", make: "Mazda" },
  { name: "Mazda RX-8 Spirit R '12", make: "Mazda" },
  { name: "Mazda RX-Vision '15", make: "Mazda" },
  { name: "Mazda RX-Vision GT3 Concept", make: "Mazda" },
  { name: "Mazda RX-Vision GT3 Concept Stealth Model", make: "Mazda" },
  { name: "Mazda RX500 '70", make: "Mazda" },
  { name: "RE Amemiya FD3S RX-7", make: "Mazda" },
  { name: "Mazda 3 X Burgundy Selection '19", make: "Mazda" },
  // McLaren
  { name: "McLaren 650S Coupe '14", make: "McLaren" },
  { name: "McLaren 650S Gr.4", make: "McLaren" },
  { name: "McLaren 650S GT3 '15", make: "McLaren" },
  { name: "McLaren MP4-12C '10", make: "McLaren" },
  { name: "McLaren F1 '94", make: "McLaren" },
  {
    name: "McLaren F1 GTR - BMW (Kokusai Kaihatsu UK Racing) '95",
    make: "McLaren",
  },
  { name: "McLaren P1 GTR '16", make: "McLaren" },
  { name: "McLaren Ultimate Vision Gran Turismo", make: "McLaren" },
  { name: "McLaren Ultimate Vision Gran Turismo Gr.1", make: "McLaren" },
  { name: "McLaren MP4-4 '88", make: "McLaren" },
  // Mercedes-Benz
  { name: "Mercedes-AMG C 63 S Coupé", make: "Mercedes-Benz" },
  { name: "Mercedes-AMG GT Black Series '20", make: "Mercedes-Benz" },
  { name: "Mercedes-AMG GT R '17", make: "Mercedes-Benz" },
  { name: "Mercedes-AMG GT S '15", make: "Mercedes-Benz" },
  { name: "Mercedes-AMG GT Safety Car", make: "Mercedes-Benz" },
  {
    name: "Mercedes-AMG GT3 (AMG-Team HTP-Motorsport) '16",
    make: "Mercedes-Benz",
  },
  { name: "Mercedes-Benz 300 SEL 6.8 AMG '71", make: "Mercedes-Benz" },
  { name: "Mercedes-Benz 300 SL (W194) CN.194 '52", make: "Mercedes-Benz" },
  { name: "Mercedes-Benz 300 SL Coupe '54", make: "Mercedes-Benz" },
  { name: "Mercedes-Benz A 45 AMG 4Matic '13", make: "Mercedes-Benz" },
  { name: "Mercedes-Benz AMG Vision Gran Turismo", make: "Mercedes-Benz" },
  {
    name: "Mercedes-Benz AMG Vision Gran Turismo LH Edition",
    make: "Mercedes-Benz",
  },
  {
    name: "Mercedes-Benz AMG Vision Gran Turismo Racing Series",
    make: "Mercedes-Benz",
  },
  { name: "Mercedes-Benz S Barker Tourer '29", make: "Mercedes-Benz" },
  { name: "Mercedes-Benz SLR McLaren '09", make: "Mercedes-Benz" },
  { name: "Mercedes-Benz SLS AMG '10", make: "Mercedes-Benz" },
  { name: "Mercedes-Benz SLS AMG Gr.4", make: "Mercedes-Benz" },
  { name: "Mercedes-Benz SLS AMG GT3 '11", make: "Mercedes-Benz" },
  { name: "Sauber Mercedes C9 '89", make: "Mercedes-Benz" },
  { name: "Mercedes-AMG GT3 '20", make: "Mercedes-Benz" },
  // Mini
  { name: "MINI Clubman Vision Gran Turismo", make: "Mini" },
  { name: "MINI COOPER S '05", make: "Mini" },
  { name: "Mini-Cooper 'S' '65", make: "Mini" },
  // Mitsubishi
  {
    name: "Mitsubishi Concept XR-PHEV EVOLUTION Vision Gran Turismo",
    make: "Mitsubishi",
  },
  { name: "Mitsubishi GTO Twin Turbo '91", make: "Mitsubishi" },
  {
    name: "Mitsubishi Lancer Evolution Final Edition Gr.3",
    make: "Mitsubishi",
  },
  {
    name: "Mitsubishi Lancer Evolution Final Edition Gr.4",
    make: "Mitsubishi",
  },
  {
    name: "Mitsubishi Lancer Evolution Final Edition Gr.B Rally Car",
    make: "Mitsubishi",
  },
  {
    name: "Mitsubishi Lancer Evolution Final Edition Gr.B Road Car",
    make: "Mitsubishi",
  },
  { name: "Mitsubishi Lancer Evolution Final Edition '15", make: "Mitsubishi" },
  { name: "Mitsubishi Lancer Evolution IV GSR '96", make: "Mitsubishi" },
  { name: "Mitsubishi Lancer Evolution V GSR '98", make: "Mitsubishi" },
  {
    name: "Mitsubishi Lancer Evolution VI GSR T.M. EDITION Special Color Package '99",
    make: "Mitsubishi",
  },
  { name: "Mitsubishi Lancer Evolution III GSR '95", make: "Mitsubishi", new: true },
  // Nissan
  { name: "Nissan 180SX Type X '96", make: "Nissan" },
  { name: "Nissan CONCEPT 2020 Vision Gran Turismo", make: "Nissan" },
  { name: "Nissan Fairlady 240ZG (HS30) '71", make: "Nissan" },
  { name: "Nissan Fairlady Z (Z34) '08", make: "Nissan" },
  {
    name: "Nissan Fairlady Z 300ZX TwinTurbo 2seater (Z32) '89",
    make: "Nissan",
  },
  { name: "Nissan Fairlady Z 432 '69", make: "Nissan" },
  { name: "Nissan Fairlady Z Version S (Z33) '07", make: "Nissan" },
  { name: "Nissan GT-R LM NISMO '15", make: "Nissan" },
  { name: "Nissan GT-R Nismo GT3 '18", make: "Nissan" },
  { name: "Nissan GT-R Gr.4", make: "Nissan" },
  { name: "Nissan GT-R Gr.B Rally Car", make: "Nissan" },
  { name: "Nissan GT-R NISMO '17", make: "Nissan" },
  { name: "Nissan GT-R NISMO GT3 N24 Schulze Motorsport '13", make: "Nissan" },
  { name: "Nissan GT-R Premium Edition '17", make: "Nissan" },
  { name: "Nissan GT-R Safety Car", make: "Nissan" },
  { name: "Nissan MOTUL AUTECH GT-R '16", make: "Nissan" },
  { name: "Nissan PENNZOIL Nismo GT-R '99", make: "Nissan" },
  { name: "Nissan R92CP '92", make: "Nissan" },
  { name: "Nissan SILEIGHTY '98", make: "Nissan" },
  { name: "Nissan SILVIA K's Dia Selection (S13) '90", make: "Nissan" },
  { name: "Nissan SILVIA Q's (S13) '88", make: "Nissan" },
  { name: "Nissan SILVIA spec-R AERO (S15) '02", make: "Nissan" },
  { name: "Nissan SILVIA spec-R AERO (S15) Touring Car", make: "Nissan" },
  { name: "Nissan SILVIA K's Type S (S14) ’94", make: "Nissan" },
  { name: "Nissan Silvia K's Aero (S14) '96", make: "Nissan" },
  { name: "Nissan Skyline 2000GT-R (KPGC110) '73", make: "Nissan" },
  { name: "Nissan SKYLINE GT-R V • spec (R33) '97", make: "Nissan" },
  { name: "Nissan SKYLINE GT-R V • spec II (R32) '94", make: "Nissan" },
  { name: "Nissan SKYLINE GT-R V • spec II Nür (R34) '02", make: "Nissan" },
  { name: "Nissan SKYLINE Hard Top 2000GT-R (KPGC10) '70", make: "Nissan" },
  {
    name: "Nissan SKYLINE Super Silhouette Group 5 (KDR30) '84",
    make: "Nissan",
  },
  { name: "Nissan XANAVI NISMO GT-R '08", make: "Nissan" },
  { name: "Nissan Z Performance '23", make: "Nissan" },
  { name: "Nissan GT-R NISMO (R32) '90", make: "Nissan" },
  // Pagani
  { name: "Pagani Huayra '13", make: "Pagani" },
  { name: "Pagani Zonda R '09", make: "Pagani" },
  // Peugeot
  { name: "Peugeot 205 Turbo 16 Evolution 2 '86", make: "Peugeot" },
  { name: "Peugeot 208 GTi by Peugeot Sport '14", make: "Peugeot" },
  { name: "Peugeot 908 HDi FAP - Team Peugeot Total '10", make: "Peugeot" },
  { name: "Peugeot L500R HYbrid Vision Gran Turismo, 2017", make: "Peugeot" },
  { name: "Peugeot L750R HYbrid Vision Gran Turismo, 2017", make: "Peugeot" },
  { name: "Peugeot RCZ Gr.3", make: "Peugeot" },
  { name: "Peugeot RCZ Gr.3 Road Car", make: "Peugeot" },
  { name: "Peugeot RCZ Gr.4", make: "Peugeot" },
  { name: "Peugeot RCZ Gr.B Rally Car", make: "Peugeot" },
  { name: "Peugeot RCZ GT Line '15", make: "Peugeot" },
  { name: "PEUGEOT Vision Gran Turismo", make: "Peugeot" },
  { name: "PEUGEOT Vision Gran Turismo Gr.3", make: "Peugeot" },
  // Plymouth
  { name: "Plymouth Superbird '70", make: "Plymouth" },
  { name: "Plymouth XNR Ghia Roadster '60", make: "Plymouth" },
  // Pontiac
  { name: "Pontiac Firebird Trans Am '78", make: "Pontiac" },
  { name: "Pontiac GTO The Judge '69", make: "Pontiac" },
  // Porsche
  { name: "Porsche 356 A1500 GS Carrera '56", make: "Porsche" },
  { name: "Porsche 356 A1500 GS GT Carrera Speedster '56", make: "Porsche" },
  { name: "Porsche 911 Carrera RS (964) '92", make: "Porsche" },
  { name: "Porsche 911 Carrera RS (993) '95", make: "Porsche" },
  { name: "Porsche 911 Carrera RS Club Sport (993) '95", make: "Porsche" },
  { name: "Porsche 911 GT1 Strassenversion '97", make: "Porsche" },
  { name: "Porsche 911 GT3 (996) '01", make: "Porsche" },
  { name: "Porsche 911 GT3 (997) '09", make: "Porsche" },
  { name: "Porsche 911 GT3 RS (991) '16", make: "Porsche" },
  { name: "Porsche 911 RSR (991) '17", make: "Porsche" },
  { name: "Porsche 911 Turbo (930) '81", make: "Porsche" },
  { name: "Porsche 917 Living Legend", make: "Porsche" },
  { name: "Porsche 917K '70", make: "Porsche" },
  { name: "Porsche 918 Spyder '13", make: "Porsche" },
  { name: "Porsche 919 Hybrid (Porsche Team) '16", make: "Porsche" },
  { name: "Porsche 962 C '88", make: "Porsche" },
  { name: "Porsche Carrera GT '04", make: "Porsche" },
  { name: "Porsche Cayman GT4 Clubsport '16", make: "Porsche" },
  { name: "Porsche Spyder type 550-1500RS '55", make: "Porsche" },
  { name: "Porsche Taycan Turbo S '19", make: "Porsche" },
  { name: "Porsche Vision Gran Turismo", make: "Porsche" },
  { name: "Porsche Vision Gran Turismo Spyder", make: "Porsche" },
  { name: "Porsche Cayman GT4 '16", make: "Porsche" },
  { name: "Porsche 911 Carrera RS (901) '73", make: "Porsche" },
  { name: "RUF CTR3 '07", make: "Porsche" },
  { name: "Porsche 959 '87", make: "Porsche" },
  { name: "Porsche Carrera GTS (904) '64", make: "Porsche" },
  // Radical
  { name: "Radical SR3 SL", make: "Radical" },
  // Renault
  { name: "Renault 5 Turbo '80", make: "Renault" },
  { name: "Renault Sport Clio R.S. 220 EDC Trophy '15", make: "Renault" },
  { name: "Renault Sport Clio R.S. 220 EDC Trophy '16", make: "Renault" },
  { name: "Renault Sport Clio V6 24V '00", make: "Renault" },
  { name: "Renault Sport Mégane Gr.4", make: "Renault" },
  { name: "Renault Sport Mégane R.S. Trophy '11", make: "Renault" },
  { name: "Renault Sport Mégane R.S. Trophy Safety Car", make: "Renault" },
  { name: "Renault Sport Mégane Trophy '11", make: "Renault" },
  { name: "Renault Sport R.S.01 '16", make: "Renault" },
  { name: "Renault Sport R.S.01 GT3 '16", make: "Renault" },
  { name: "Renault R8 Gordini '66", make: "Renault" },
  // Roadster Shop
  { name: "Roadster Shop Rampage", make: "Roadster Shop" },
  // Shelby
  { name: "Shelby Cobra 427 '66", make: "Shelby" },
  { name: "Shelby Cobra Daytona Coupe '64", make: "Shelby" },
  { name: "Shelby GT350 '65", make: "Shelby" },
  // Subaru
  { name: "Subaru BRZ Drift Car '17", make: "Subaru" },
  { name: "Subaru BRZ S '15", make: "Subaru" },
  { name: "Subaru BRZ S ’21", make: "Subaru" },
  { name: "Subaru BRZ GT300 ’21", make: "Subaru" },
  { name: "Subaru BRZ STI Sport '18", make: "Subaru" },
  { name: "Subaru IMPREZA Coupe WRX typeR STi Version VI '99", make: "Subaru" },
  {
    name: "Subaru IMPREZA Premium Sport Coupe 22B-STi Version '98",
    make: "Subaru",
  },
  { name: "Subaru VIZIV GT Vision Gran Turismo", make: "Subaru" },
  { name: "Subaru WRX Gr.3", make: "Subaru" },
  { name: "Subaru WRX Gr.4", make: "Subaru" },
  { name: "Subaru WRX Gr.B Rally Car", make: "Subaru" },
  { name: "Subaru WRX Gr.B Road Car", make: "Subaru" },
  { name: "Subaru WRX STI Isle of Man Time Attack Car '16", make: "Subaru" },
  { name: "Subaru WRX STI Type S '14", make: "Subaru" },
  { name: "Subaru Impreza Sedan WRX STi '04", make: "Subaru", new: true },
  // Suzuki
  { name: "Suzuki SWIFT Sport '07", make: "Suzuki" },
  { name: "Suzuki Swift Sport '17", make: "Suzuki" },
  { name: "Suzuki Swift Sport Gr.4", make: "Suzuki" },
  { name: "Suzuki Cappuccino '91", make: "Suzuki" },
  {
    name: "Suzuki V6 Escudo Pikes Peak Special '98",
    make: "Suzuki",
  },
  {
    name: "Suzuki Vision Gran Turismo",
    make: "Suzuki",
  },
  {
    name: "Suzuki Vision Gran Turismo (Gr.3 Version)",
    make: "Suzuki",
  },
  // Tesla
  { name: "Tesla Motors Model S Signature Performance '12", make: "Tesla" },
  // Toyota
  { name: "Toyota 86 Gr.4", make: "Toyota" },
  { name: "Toyota 86 Gr.B Rally Car", make: "Toyota" },
  { name: "Toyota 86 GRMN '16", make: "Toyota" },
  { name: "Toyota 86 GT '15", make: "Toyota" },
  { name: "Toyota 86 GT 'Limited' '16", make: "Toyota" },
  { name: "Toyota 2000GT '67", make: "Toyota" },
  { name: "Toyota Aqua S '11", make: "Toyota" },
  { name: "Toyota Castrol TOM'S SUPRA '97", make: "Toyota" },
  { name: "Toyota CELICA GT-FOUR (ST205)", make: "Toyota" },
  {
    name: "Toyota Celica GT-FOUR Rally Car (ST205) '95",
    make: "Toyota",
  },
  { name: "Toyota COROLLA LEVIN GT-APEX (AE86) '83", make: "Toyota" },
  { name: "Toyota Crown Athlete G '13", make: "Toyota" },
  { name: "Toyota Crown Athlete G Safety Car", make: "Toyota" },
  { name: "Toyota GR010 HYBRID ‘21", make: "Toyota" },
  { name: "Toyota FT-1", make: "Toyota" },
  { name: "Toyota FT-1 Vision Gran Turismo", make: "Toyota" },
  { name: "Toyota FT-1 Vision Gran Turismo Gr.3", make: "Toyota" },
  { name: "Toyota GR86 RZ '21", make: "Toyota" },
  { name: "Toyota GR Supra (Nürburgring '19 Livery)", make: "Toyota" },
  { name: "Toyota GR Supra Racing Concept '18", make: "Toyota" },
  { name: "Toyota GR Supra RZ '19", make: "Toyota" },
  { name: "Toyota GR Supra RZ '20", make: "Toyota" },
  {
    name: "Toyota GR Yaris 1st Edition RZ “High Performance” '20",
    make: "Toyota",
  },
  { name: "Toyota MR2 GT-S '97", make: "Toyota" },
  { name: "Toyota PRIUS G '09", make: "Toyota" },
  { name: "Toyota S-FR '15", make: "Toyota" },
  { name: "Toyota S-FR Racing Concept '16", make: "Toyota" },
  { name: "Toyota SPORTS 800 '65", make: "Toyota" },
  { name: "Toyota SPRINTER TRUENO GT-APEX (AE86) '83", make: "Toyota" },
  {
    name: "Toyota SPRINTER TRUENO GT-APEX (AE86 Shuichi Shigeno Version) '00",
    make: "Toyota",
  },
  { name: "Toyota SUPRA 3.0GT Turbo A '88", make: "Toyota" },
  { name: "Toyota SUPRA RZ '97", make: "Toyota" },
  { name: "Toyota TS030 Hybrid '12", make: "Toyota" },
  { name: "Toyota TS050 - Hybrid (Toyota Gazoo Racing) '16", make: "Toyota" },
  { name: "Toyota Tundra TRD Pro '19", make: "Toyota" },
  { name: "Toyota Alphard Executive Lounge '18", make: "Toyota" },
  { name: "Dallara SF19 Super Formula - Toyota '19", make: "Toyota" },
  {
    name: "Dallara SF23 Super Formula - Toyota '23",
    make: "Toyota",
  },
  { name: "Toyota GR Corolla MORIZO Edition '22", make: "Toyota", new: true },
  { name: "Toyota Ambulance Himedic '21", make: "Toyota", new: true },
  // TVR
  { name: "TVR Tuscan Speed 6 '00", make: "TVR" },
  // Volkswagen
  { name: "Volkswagen 1200 '66", make: "Volkswagen" },
  { name: "Volkswagen Beetle Gr.3", make: "Volkswagen" },
  { name: "Volkswagen Golf I GTI '83", make: "Volkswagen" },
  { name: "Volkswagen Golf VII GTI '14", make: "Volkswagen" },
  { name: "Volkswagen GTI Roadster Vision Gran Turismo", make: "Volkswagen" },
  { name: "Volkswagen GTI Supersport Vision Gran Turismo", make: "Volkswagen" },
  { name: "Volkswagen GTI Vision Gran Turismo Gr.3", make: "Volkswagen" },
  { name: "Volkswagen Polo GTI '14", make: "Volkswagen" },
  { name: "Volkswagen Scirocco Gr.4", make: "Volkswagen" },
  { name: "Volkswagen Scirocco R '10", make: "Volkswagen" },
  { name: "Volkswagen typ2(T1) SambaBus '62", make: "Volkswagen" },
  { name: "Volkswagen ID.R '19", make: "Volkswagen" },
  // Wicked Fabrication
  { name: "Wicked Fabrication GT 51", make: "Wicked Fabrication" },
  // Zagato
  { name: "IsoRivolta Zagato Vision Gran Turismo", make: "Zagato" },
];

export const carsOptions = () => {
  return cars.map((car) => {
    return {
      label: car.name,
      value: car.name,
    };
  });
};

export const participants = [{ name: "Laurel" }, { name: "René" }];

export const tiresOptions = () => {
  return [
    { label: "Terre", value: "Terre" },
    { label: "Comfort", value: "Comfort" },
    { label: "Comfort - Dur", value: "Comfort - Dur" },
    { label: "Comfort - Moyen", value: "Comfort - Moyen" },
    { label: "Comfort - Mou", value: "Comfort - Mou" },
    { label: "Sport", value: "Sport" },
    { label: "Sport - Dur", value: "Sport - Dur" },
    { label: "Sport - Moyen", value: "Sport - Moyen" },
    { label: "Sport - Mou", value: "Sport - Mou" },
    { label: "Course", value: "Course" },
    { label: "Course - Dur", value: "Course - Dur" },
    { label: "Course - Moyen", value: "Course - Moyen" },
    { label: "Course - Mou", value: "Course - Mou" },
    {
      label: "Course - Pluie Intermediaire",
      value: "Course - Pluie Intermediaire",
    },
    { label: "Course - Pluie Forte", value: "Course - Pluie Forte" },
  ];
};

export const participantsOptions = () => {
  return participants.map((par) => {
    return {
      label: par.name,
      value: par.name,
    };
  });
};
