import bg from "assets/blur-bg.jpg";
import lock from "assets/car-locked.png";

export default (theme) => ({
  base: {
    minHeight: "100vh",
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    overflow: "hidden",
  },
  "@keyframes rainbow_animation": {
    "0%": {
      backgroundPosition: "0 0",
    },

    "100%": {
      backgroundPosition: "100% 0",
    },
  },
  backIcon: {
    transform: "rotate(90deg)",
    position: "absolute",
    top: "30px",
    left: "20px",
    zIndex: "100",
  },
  descriptionIcon: {
    position: "absolute",
    right: "20px",
    top: "30px",
    zIndex: "400",
    padding: "1px",
    backgroundColor: "white",
    borderRadius: "50%",

    "&:hover": {
      boxShadow: "0px 0px 0px 1px white",
    },
  },

  lineItem: {
    display: "grid",
    gridTemplateColumns: "1fr 1.25fr",
    columnGap: "10px",
    margin: "6px 0",
  },
  lineItemLabel: {
    textAlign: "right",
    color: "cyan",
  },
  lineItemValue: {
    textAlign: "left",
    color: "white",
  },
  volumeIcon: {
    position: "absolute",
    right: "15px",
    bottom: "15px",
    zIndex: "400",
    padding: "5px",
  },
  "volumeIcon--mute": {
    opacity: 0.7,
  },
  editIcon: {
    position: "absolute",
    left: "15px",
    bottom: "15px",
    zIndex: "400",
    padding: "5px",

    opacity: 0.5,

    "&:hover": {
      opacity: 1,
    },
  },
  editIconTitle: {
    position: "relative",
    opacity: 0.5,
    marginLeft: 10,
    top: 2,

    "&:hover": {
      opacity: 1,
    },
  },
  imageFill: {
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
    position: "relative",
    pointerEvents: "none",
    transition: "all 1s ease, height 0s, width 0s",
    filter: "blur(2px)",
    " -webkit-filter": "blur(2px)",
  },
  imageOverlay: {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    zIndex: "10",
    opacity: "0.3",
    filter: "saturate(500%)",
  },
  imageOverlayBlack: {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    zIndex: "11",
    opacity: "0.2",
    backgroundColor: "black",
  },
  flagOverlay: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    left: "0px",
    top: "0px",
    opacity: 0.07,
    objectFit: "cover",
  },
  mainTitle: {
    position: "absolute",
    top: "-50px",
    left: "10px",
    width: "200%",
    textAlign: "center",
    fontSize: "25px !important",
    color: "white",
    userSelect: "none",
    textTransform: "capitalize",
    opacity: 0.6,
    fontStyle: "unset !important",
  },
  content: {
    zIndex: 20,
    position: "absolute",
    left: "0px",
    top: "0px",
    width: "calc(100vw)",
    padding: "0",
    maxHeight: "100vh",
    overflowY: "scroll",
    overflowX: "hidden",
    marginBottom: "100px",
  },
  top: {
    marginTop: "5vh",
    marginBottom: "2vh",
    height: "20vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "40px",
    fontFamily: "gtfont",
    color: "white",
    position: "relative",

    "& h1": {
      textAlign: "center",
      fontSize: "60px",
      fontStyle: "italic",
      color: "white",
    },
  },
  laurelStats: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",

    "& p": {
      margin: "25px",
      marginLeft: "40px",
      fontSize: "50px",
      fontFamily: "gtfont",
      color: "white",
      position: "relative",
      top: "2px",
    },
  },
  reneStats: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& p": {
      margin: "25px",
      marginRight: "40px",
      fontSize: "50px",
      fontFamily: "gtfont",
      color: "white",
      position: "relative",
      top: "2px",
    },
  },
  statsAvatar: {
    width: "15vh",
    height: "15vh",
    borderRadius: "50%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    boxShadow: "0px 2px 5px 1px rgba(0,0,0,0.2)",
    transition: "all 0.4s ease",
    position: "relative",
  },
  statsAvatarWinner: {
    // boxShadow: '0px 0px 0px 6px green',
    margin: "0 35px",
    transform: "scale(1)",
  },
  statsAvatarLoser: {
    opacity: 0.35,
    transform: "scale(0.9)",
  },
  winnerCrown: {
    position: "absolute",
    left: "-30%",
    top: "-10%",
    width: "160%",
    height: "140%",
  },
  raceContainer: {
    padding: "0 10vw 10vh",
    color: "white",
    position: "relative",
  },
  noRacesText: {
    marginTop: "0px",
    backgroundColor: "rgba(0,0,0,0.8)",
    padding: "30px",
    color: "white",
    borderRadius: "6px",
    textAlign: "center",
  },
  chronoImage: {
    height: 60,
    marginBottom: 8,
    position: "relative",
    top: "-3px",
  },
  topLabel: {
    padding: "4px 10vw",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "18px",
    color: "white",
    zIndex: 200,
  },
  race: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.45)",
    padding: "12px",
    marginBottom: "8px",
    borderRadius: "6px",
    transition: "transform 0.2s ease",

    "& p": {
      margin: "6px",
    },
  },
  raceActive: {
    backgroundColor: "rgba(0,0,0,0.8)",
    border: "solid 2px red",
    transform: "scale(1.05)",

    "& $carImage": {
      width: "calc(15vw * 1.07)",
      height: "calc(10vw * 1.07)",
    },
  },
  raceDone: {
    opacity: 1,
    backgroundColor: "rgba(20,20,20,0.65)",
  },
  raceFuture: {
    opacity: 0.75,
  },
  raceTimeTrial: {
    backgroundColor: "rgba(0,0,100,0.75)",
  },
  laurelContainer: {
    minWidth: "25vw",
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  reneContainer: {
    minWidth: "25vw",
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  raceInfoContainer: {
    width: "100%",
    textAlign: "center",

    "& h3": {
      fontSize: "24px",
      marginBottom: "18px",
      marginLeft: "30px",
    },
  },
  raceInfoContainerMobile: {
    display: "none",
  },
  trackName: {
    cursor: "pointer",

    "&:hover": {
      textDecoration: "underline",
    },
  },
  carImage: {
    width: "15vw",
    height: "10vw",
    minWidth: "15vw",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    position: "relative",
    borderRadius: "4px",
    backgroundRepeat: "no-repeat",

    cursor: "pointer",
    transition: "transform 0.1s",

    "&:hover": {
      transform: "scale(1.05)",
    },

    "& p": {
      position: "absolute",
      bottom: "0px",
      left: "0px",
      width: "100%",
      textAlign: "center",
      fontSize: "12px",
      margin: "0px",
      padding: "4px",
      backgroundColor: "rgba(0,0,0,0.5)",
    },

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  carNicknameLaurel: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    color: "royalblue",
    inset: "10px -10px auto auto",
    padding: "8px 10px 16px 16px",
    clipPath:
      "polygon(0 0,100% 0,100% calc(100% - 10px),calc(100% - 10px) 100%, calc(100% - 10px) calc(100% - 10px),0 calc(100% - 10px), 15px calc(50% - 10px/2))",
    background: "white",
    boxShadow: "0 calc(-10px) 0 inset #0005",
    fontSize: "12px",
    lineHeight: "12px",
    textAlign: "center",
    paddingRight: "0px",
    opacity: 0.88,
  },
  carNicknameRene: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    color: "royalblue",
    inset: "10px auto auto -10px",
    padding: "8px 10px 16px 16px",
    clipPath:
      "polygon(0 0,100% 0,100% calc(100% - 10px),calc(100% - 10px) 100%, calc(100% - 10px) calc(100% - 10px),0 calc(100% - 10px), 15px calc(50% - 10px/2))",
    transform: "scaleX(-1)",
    background: "white",
    boxShadow: "0 calc(-10px) 0 inset #0005",
    fontSize: "12px",
    lineHeight: "12px",
    textAlign: "center",
    paddingRight: "0px",
    opacity: 0.88,
  },
  carChoiceLockedLaurel: {
    backgroundImage: `url(${lock})`,
    width: "30px",
    height: "30px",
    position: "absolute",
    bottom: 0,
    right: -14,
    backgroundSize: "contain",
    zIndex: "1000",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    opacity: 0.8,
  },
  carChoiceLockedRene: {
    backgroundImage: `url(${lock})`,
    width: "30px",
    height: "30px",
    position: "absolute",
    bottom: 0,
    left: -14,
    backgroundSize: "contain",
    zIndex: "1000",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    opacity: 0.8,
  },
  rainbowTextAnimated: {
    background:
      "linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff, #0099ff)",
    "-webkit-background-clip": "text",
    backgroundClip: "text",
    color: "transparent",
    animation: "$rainbow_animation 2s linear infinite",
    backgroundSize: "450% 100%",
    filter: "brightness(150%) saturate(150%)",
  },
  vs: {
    display: "none",
  },
  carTimeTrialText: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "8px 0 0",
    width: "100%",
    fontSize: "30px",
    textAlign: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  winnerTrophy: {
    width: "10vw",
    minWidth: "10vw",
  },
  loserOverlay: {
    backgroundColor: "red",
    opacity: 0.3,
    position: "absolute",
    left: "0px",
    top: "0px",
    width: "100%",
    height: "100%",
  },
  winnerOverlay: {
    backgroundColor: "rgb(0,220,0)",
    opacity: 0.3,
    position: "absolute",
    left: "0px",
    top: "0px",
    width: "100%",
    height: "100%",
  },
  ch1: {
    position: "fixed",
    right: "-11vw",
    top: "-17vh",
    width: "49.5vw",
    zIndex: -1,
    userSelect: "none",
  },
  ch2: {
    position: "fixed",
    left: "-11vw",
    top: "-17vh",
    transform: "scaleX(-1)",
    width: "49.5vw",
    zIndex: -1,
    userSelect: "none",
  },
  videoUrlImage: {
    marginTop: "8px",
    width: "30px",
    cursor: "pointer",
    filter: "saturate(80%)",
    transition: "tranform 0.1s",

    "&:hover": {
      filter: "saturate(100%)",
    },
  },
  openCarOverlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 1000,

    "& img": {
      maxWidth: "calc(100% - 60px)",
      maxHeight: "calc(100% - 60px)",
      margin: "50px",
    },

    "& p": {
      position: "fixed",
      bottom: "25px",
      width: "100vw",
      textAlign: "center",
      color: "white",
      fontSize: "12px",
    },
  },
  openTrackOverlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 1000,

    "& img": {
      maxWidth: "calc(100% - 60px)",
      maxHeight: "calc(100% - 60px)",
      margin: "50px",
    },

    "& p": {
      position: "fixed",
      bottom: "25px",
      width: "100vw",
      textAlign: "center",
      color: "white",
      fontSize: "12px",
    },
  },
  "@media (max-width: 950px)": {
    raceContainer: {
      position: "relative",
      padding: "0 1vw 5vh",
    },
    raceActive: {
      transform: "scale(1)",

      "& $carImage": {
        width: "100%",
        height: "max(20vw, 150px)",
      },
    },
    topLabel: {
      padding: "0 1vw",
    },
    laurelStats: {
      "& p": {
        margin: "15px",
        marginLeft: "15px",
      },
    },
    reneStats: {
      "& p": {
        margin: "15px",
        marginRight: "15px",
      },
    },
    statsAvatarWinner: {
      transform: "scale(1)",
      margin: "0 20px",
    },
    statsAvatarLoser: {
      transform: "scale(0.9)",
    },
    winnerTrophy: {
      display: "none",
    },
    carImage: {
      width: "100%",
      height: "max(20vw, 150px)",

      "& p": {
        fontSize: "10px",
      },
    },
    top: {
      marginTop: "8vh",
      marginBottom: "3vh",
    },
    mainTitle: {
      left: "0px",
      top: "-60px",
    },
    raceInfoContainer: {
      display: "none",
    },
    raceInfoContainerMobile: {
      display: "block",
      fontSize: "12px",

      "& h3": {
        fontSize: "20px",
        marginTop: "8px",
        marginBottom: "20px",
        lineHeight: "14px",
      },
    },
    race: {
      position: "relative",
      display: "block",
      padding: "12px",
    },
    editIconTitle: {
      position: "relative",
      top: "4px",
      width: "16px",
    },

    laurelContainer: {
      display: "inline-block",
      width: "calc(50% - 16px)",
      marginRight: "16px",
    },
    reneContainer: {
      display: "inline-block",
      width: "calc(50% - 16px)",
      marginLeft: "16px",
    },
    vs: {
      display: "block",
      position: "absolute",
      top: "80px",
      left: "calc(50% - 17.5px)",
      fontStyle: "italic",
      color: "yellow",
    },
    carChoiceLockedLaurel: {
      width: "12px",
      right: -5,
    },
    carChoiceLockedRene: {
      width: "12px",
      left: -5,
    },
    carNicknameLaurel: {
      fontSize: "10px",
      lineHeight: "10px",
      paddingLeft: 14,
      paddingRight: 2,
      top: -4,
    },
    carNicknameRene: {
      fontSize: "10px",
      lineHeight: "10px",
      paddingLeft: 14,
      paddingRight: 2,
      top: -4,
    },
    lineItem: {
      gridTemplateColumns: "0.70fr 1.25fr",
    },
  },
});
