import React from 'react';
import { Container } from 'react-grid-system';

const _Container = ({ marginTop, marginBottom, style = {}, children, ...props }) => {
  const marginTopVal = typeof marginTop === 'number' ? marginTop : (marginTop === true ? 16 : null);
  const marginBottomVal = typeof marginBottom === 'number' ? marginBottom : (marginBottom === true ? 16 : null);
  return (
    <Container
      {...props}
      style={{
        ...style,
        marginTop: marginTopVal,
        marginBottom: marginBottomVal
      }}
    >
      {children}
    </Container>
  )
};

export default _Container;