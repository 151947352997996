import bg from "assets/blur-bg.jpg";

export default (theme) => ({
  base: {
    minHeight: "100vh",
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingBottom: "100px",

    "& h1": {
      textAlign: "center",
      fontSize: "60px",
      fontStyle: "italic",
      color: "white",
      marginBottom: "30px",
      userSelect: "none",
      fontFamily: "gtfontoblique",
    },
  },
  gtlogo: {
    cursor: "pointer",
    userSelect: "none",
  },
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // backgroundColor: 'rgba(0,0,0,0.3)',
    padding: "20px",

    "& img": {
      height: "60px",
    },
  },
  leaderboard: {
    textAlign: "right",
    color: "white",
    position: "relative",
    fontSize: "14px",
    userSelect: "none",

    "& p": {
      margin: "4px",
    },
  },
  garageIcon: {
    position: "absolute",
    left: "-105px",
    top: "calc(50% - 20px)",
    transition: "transform 0.1s",

    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  leaderboardTrophy: {
    position: "absolute",
    left: "-35px",
    top: "8px",
    fontSize: "30px",
  },
  totalWinLeaderboard: {
    display: "inline-block",
    width: "15px",
    textAlign: "center",
  },
  championshipList: {
    margin: "0 50px",
    display: "flex",
    flexWrap: "wrap",
  },
  championshipListCentered: {
    justifyContent: "center",
  },
  championship: {
    width: "calc(33.33% - 30px)",
    margin: "10px",
    height: "15vw",
    backgroundColor: "rgba(0,0,0,0.88)",
    borderRadius: "3px",
    transition: "all 0.2s ease",
    cursor: "pointer",
    filter: "saturate(100%)",
    overflow: "hidden",
    position: "relative",
    userSelect: "none",

    "&:hover": {
      transform: "scale(1.03)",
      filter: "saturate(200%)",
      boxShadow: "0 10px 20px rgba(0,0,0,0.10), 0 6px 6px rgba(0,0,0,0.15)",
    },
  },
  archived: {
    opacity: "0.75",
  },
  done: {},
  timeTrial: {
    border: "solid 4px blue",
    backgroundColor: "blue",
  },
  winnerTrophy: {
    position: "absolute",
    width: "12vw",
    left: "calc(50% - 6vw)",
    top: "calc(50% - 6vw - 30px)",
    zIndex: 100,
  },
  flagOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: "0px",
    opacity: 0.4,
    zIndex: 50,
  },
  topInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    padding: "15px 20px",
    color: "white",
    position: "relative",
  },
  chronoImage: {
    position: "absolute",
    left: "calc(50% - 55px)",
    width: "110px",
    top: "calc(50% - 65px)",
    opacity: 1,
  },
  imageFill: {
    height: "15vw",
    backgroundSize: "cover",
    backgroundPosition: "center bottom",
    position: "relative",
  },
  imageOverlay: {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    zIndex: "10",
    opacity: "0.3",
    filter: "saturate(150%)",
  },
  archiveLink: {
    cursor: "pointer",
    color: "white",
    marginTop: "60px",
    textAlign: "center",
    fontSize: "12px",

    "&:hover": {
      textDecoration: "underline",
    },
  },
  logout: {
    position: "fixed",
    bottom: "-20px",
    left: "0px",
    padding: "20px",
    color: "white",
    cursor: "pointer",
    opacity: 0.8,

    "&:hover": {
      opacity: 1,
    },
  },
  adminPanel: {
    position: "fixed",
    bottom: "-20px",
    right: "0px",
    padding: "20px",
    color: "white",
    cursor: "pointer",
    opacity: 0.8,

    "&:hover": {
      opacity: 1,
    },
  },
  descriptionIcon: {
    position: "absolute",
    right: "8px",
    bottom: "8px",
    zIndex: "400",
    padding: "1px",
    backgroundColor: "white",
    borderRadius: "50%",

    "&:hover": {
      boxShadow: "0px 0px 0px 1px white",
    },
  },
  signature: {
    fontSize: "8px",
    position: "fixed",
    bottom: "2px",
    left: "calc(50% - 91px)",
    color: "rgba(255,255,255,0.7)",
    pointerEvents: "none",
  },
  "@media (max-width: 950px)": {
    base: {
      "& h1": {
        fontSize: "40px",
        marginBottom: "16px",
      },
    },
    championshipList: {
      margin: "0 20px",
    },
    championship: {
      width: "100%",
      height: "40vw",
    },
    topInfo: {
      fontSize: "10px",
      padding: "8px",
    },
    imageFill: {
      height: "40vw",
    },
    winnerTrophy: {
      width: "30vw",
      left: "calc(50% - 15vw)",
      top: "calc(50% - 15vw - 20px)",
    },
    garageIcon: {
      left: "-90px",
    },
  },
});
