import bg from 'assets/blur-bg.jpg';

export default (theme) => ({
  base: {
    minHeight: '100vh',
    maxWidth: '100vw',
    // backgroundColor: theme.colors.primaryLight,
    background: 'linear-gradient(200deg, rgba(238,39,55,1) 0%, rgba(247,50,99,1) 100%)',
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingBottom: '250px',

  },
  top: {
    // clipPath: 'ellipse(85% 100% at 50% 0%)',
    position: 'relative',
    // borderRadius: '20px',
    // margin: '20px',
    padding: '0 0px 20px',
  },
  body: {
    backgroundColor: 'rgba(255,255,255,0.92)',
    margin: '36px auto',
    padding: '40px 40px',
    borderRadius: '8px',
    boxShadow: '0px 2px 10px 4px rgba(0, 0, 0, 0.2)',
    width: '930px',
    maxWidth: '100vw',
    lineHeight: '24px',
    fontSize: '18px',
    textAlign: 'left',

    '& p': {
      marginBottom: '12px',
    }
  },
  footer: {
    marginTop: '60px',
  },
  countdown: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px',
    color: 'white',
    fontSize: '24px',
  },
  g1: {
    position: 'fixed',
    left: '-100px',
    top: '-150px',
    width: '50%',
    transform: 'rotate(-30deg)',
  },
  g2: {
    position: 'fixed',
    right: '-200px',
    top: '-110px',
    width: '50%',
    transform: 'rotate(25deg)',
  },
  crowd: {
    position: 'fixed',
    left: '0px',
    bottom: '-185px',
    width: '100vw',
    pointerEvents: 'none'
  },
  giftsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',

    '& p': {
      fontSize: '14px !important',
      lineHeight: '16px !important',
      margin: '8px 0',
    }
  },
  giftPsPlus: {
    textAlign: 'center',
    margin: '20px',
    minWidth: '40%',
    maxWidth: '40%',

    '& img': {
      width: '44%'
    }
  },
  giftHoodie: {
    textAlign: 'center',
    margin: '20px',
    minWidth: '40%',
    maxWidth: '40%',

    '& img': {
      width: '100%'
    }
  }
})