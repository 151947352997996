export default (theme) => ({
  base: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '15px 20px',
    border: `solid 1px ${theme.colors.grey1}`,
    backgroundColor: props => props.bgColor ? theme.colors[props.bgColor] : theme.colors.white,
    color: props => props.color ? theme.colors[props.color] : theme.intent[props.intent || 'primary'],
    marginBottom: props => (props?.margin ?? true) ? '15px' : null,
    borderColor: props => theme.intent[props.intent || 'primary'],
  },
  icon: {
    marginRight: '15px'
  }
})