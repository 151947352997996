import React from "react";
import cn from "classnames";
import routes from "utils/routes";
import useWindowSize from "react-use/lib/useWindowSize";
import {
  useEffect,
  useState,
  useParams,
  useRef,
  useCallback,
  useHistory,
  useStyle,
} from "utils/hooks";
import { Navigation, Typo, Container, Row, Col, Icon } from "components";
import { tracks } from "data";
import Confetti from "react-confetti";
import Emoji from "a11y-react-emoji";
import { getSeededStyle } from "utils/helper";
import blurbg from "assets/blur-bg.jpg";
import { Helmet } from "react-helmet";
import champagne from "assets/champagne.gif";
import youtubeIcon from "assets/youtube-icon.png";
import chrono from "assets/chrono.png";
import Layout from "components/Layout";
import winnerSound from "assets/winner-winner-chicken-dinner.mp3";
import styles from "./styles";

import firebase from "utils/firebase";
const db = firebase.database();

export default function Championship(props) {
  const classes = useStyle(styles, props);
  const hasPlayedSound = useRef(false);
  const audioRef = useRef();
  const [openCar, setOpenCar] = useState(null);
  const [mounted, setMounted] = useState(null);
  const [mute, setMute] = useState(false);
  const [openTrack, setOpenTrack] = useState(null);
  const [championship, setChampionship] = useState({});
  const championshipRef = useRef();
  const { width, height } = useWindowSize();
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    const ref = db.ref(`championships/${params?.id}`);

    ref.on("value", (snapshot) => {
      const champ = snapshot.val();
      setChampionship(champ);
      championshipRef.current = champ;
      setMounted(true);

      setTimeout(() => {
        const element = document.getElementById("active-race");
        if (element) {
          element.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }
      }, 500);
    });

    return () => {
      ref.off();
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      if (mute) {
        audioRef.current.volume = 0;
      } else {
        audioRef.current.volume = 0.03;
      }
    }
  }, [mute]);
  const seededStyle = getSeededStyle(championship?.name);
  const activeRaceIndex = (championship?.races || []).findIndex(
    (r) => !r.winner
  );
  const activeRace = championship?.races?.[activeRaceIndex];
  const laurelWins =
    championship?.races?.filter((r) => r.winner === "Laurel")?.length || 0;
  const reneWins =
    championship?.races?.filter((r) => r.winner === "René")?.length || 0;
  const isChampionshipDone =
    championship?.races?.length > 0 &&
    !championship?.races.find((r) => !r.winner);

  const playWinSound = async () => {
    const isDone = championshipRef.current
      ? championshipRef.current?.races?.length > 0 &&
        !championshipRef.current?.races.find((r) => !r.winner)
      : false;
    if (!hasPlayedSound.current && isDone) {
      try {
        audioRef.current = new Audio(winnerSound);
        audioRef.current.volume = mute ? 0 : 0.03;
        await audioRef.current.play();
        hasPlayedSound.current = true;
      } catch (e) {
        console.log(e);
      }
    }
  };

  const showDescription = (description) => {
    alert(description);
  };

  const openCarModal = (car) => {
    if (car) {
      setOpenCar(car);
    }
  };

  const closeCarModal = () => {
    setOpenCar(null);
  };

  const openTrackModal = (track) => {
    if (track) {
      setOpenTrack(track);
    }
  };

  const closeTrackModal = () => {
    setOpenTrack(null);
  };

  const getDistanceLabel = (km) => {
    if (!km) return null;
    const st = { opacity: 0.8, paddingLeft: "8px" };

    if (km < 7) return <span style={{ ...st, color: "green" }}>(court)</span>;
    if (km < 13.5)
      return <span style={{ ...st, color: "orange" }}>(moyen)</span>;
    if (km < 21) return <span style={{ ...st, color: "red" }}>(long)</span>;
    if (km >= 21)
      return <span style={{ ...st, color: "purple" }}>(endurance)</span>;
  };

  useEffect(() => {
    const interval = setInterval(playWinSound, 500);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  let openCarUrl = require("assets/mystery-car.png")?.default;
  if (openCar) {
    try {
      openCarUrl = require(`assets/cars/${openCar}.png`)?.default;
    } catch (e) {
      console.log("error", e);
    }
  }

  const raceInfoContainerFct = (race, i, mobile = false) => {
    const {
      name: raceName,
      track,
      reverse,
      laps,
      winner,
      restrictions,
      tires,
      tireWear,
      weather,
      carType,
      maxPP,
      tuningAllowed,
      finishNotes,
      videoUrl,
    } = race || {};
    const trackData = tracks?.find((t) => t.name === track);
    const { location, flag, km } = trackData || {};
    const raceLengthKm = km && laps > 0 ? (km * laps || "").toFixed(3) : null;
    return (
      <div
        className={cn(classes.raceInfoContainer, {
          [classes.raceInfoContainerMobile]: mobile,
        })}
      >
        {championship?.isTimeTrial && (
          <img className={classes.chronoImage} src={chrono} />
        )}
        {(raceName || !championship?.isTimeTrial) && (
          <>
            <h3>
              {!championship?.isTimeTrial ? `Course #${i + 1}` : ""}
              {raceName ? `: ${raceName}` : ""}
              <Icon
                name="edit"
                color="white"
                className={classes.editIconTitle}
                size={20}
                onClick={() =>
                  history.push({
                    pathname: routes.ADMIN_CHAMPIONSHIP_RACE_FUNC(params?.id),
                    state: {
                      raceId: race?.id,
                      champId: params?.id,
                      redirect: history.location.pathname,
                    },
                  })
                }
              />
            </h3>
          </>
        )}
        <p /*className={classes.trackName} onClick={() => openTrackModal(track)}*/
        >
          <Emoji symbol={flag} label={location} /> {track}
          {reverse && (
            <span
              style={{
                paddingLeft: "4px",
                // verticalAlign: "super",
                top: "-1px",
                position: "relative",
                fontSize: "12px",
                color: "lightblue",
                fontWeight: "bold",
              }}
            >
              — reverse
            </span>
          )}
          {!championship?.isTimeTrial && (
            <p>
              <span style={{ color: "gold", paddingLeft: "8px" }}>
                {laps} Tour{laps > 1 ? "s" : ""}
              </span>
              {getDistanceLabel(raceLengthKm)}
            </p>
          )}
        </p>
        <div style={{ height: "6px" }} />
        {weather && (
          <div className={classes.lineItem}>
            <span className={classes.lineItemLabel}>Météo:</span>
            <span className={classes.lineItemValue}>{weather}</span>
          </div>
        )}
        {carType && (
          <div className={classes.lineItem}>
            <span className={classes.lineItemLabel}>Voitures:</span>
            <span className={classes.lineItemValue}>{carType}</span>
          </div>
        )}
        {tires && (
          <div className={classes.lineItem}>
            <span className={classes.lineItemLabel}>Pneus:</span>
            <span className={classes.lineItemValue}>
              {tires}
              {tireWear && tireWear > 0 ? (
                <span style={{ opacity: 0.8 }}>(usure {tireWear}x)</span>
              ) : (
                ""
              )}
            </span>
          </div>
        )}

        {maxPP && (
          <div className={classes.lineItem}>
            <span className={classes.lineItemLabel}>Max PP:</span>
            <span className={classes.lineItemValue}>{maxPP}</span>
          </div>
        )}

        {/* <div style={{ height: "4px" }} /> */}

        {(restrictions || !tuningAllowed) && (
          <div className={classes.lineItem}>
            <span className={classes.lineItemLabel} style={{ color: "cyan" }}>
              Restrictions:
            </span>
            <span className={classes.lineItemValue} style={{ color: "red" }}>
              {!tuningAllowed ? "Aucune modifications" : ""}
              {!tuningAllowed && restrictions ? " - " : ""}
              {restrictions}
            </span>
          </div>
        )}

        {championship?.date && championship?.isTimeTrial && !winner && (
          <p
            style={{
              color: "cyan",
              fontSize: "16px",
              marginTop: "16px",
            }}
          >
            Se termine le {new Date(championship.date).toLocaleDateString()} à
            11:59pm
          </p>
        )}

        {finishNotes && (
          <p style={{ color: "rgb(0,255,0)", marginTop: "20px" }}>
            {finishNotes}
          </p>
        )}
        {videoUrl && (
          <a target="_blank" rel="noreferrer" href={videoUrl}>
            <img className={classes.videoUrlImage} src={youtubeIcon} />
          </a>
        )}
      </div>
    );
  };

  return (
    <Layout className={classes.base}>
      <Helmet>
        <title>{championship?.name}</title>
      </Helmet>
      <div
        id="clicker"
        className={classes.imageFill}
        style={{
          opacity: championship?.imageIndex ? 1 : 0,
          backgroundImage: `url(${
            championship?.imageIndex
              ? require(`assets/wallpaper/${championship?.imageIndex}.jpg`)
                  .default
              : blurbg
          })`,
        }}
      >
        <div
          className={classes.imageOverlay}
          style={{ backgroundColor: `${seededStyle.rgbMain}` }}
        />
        <div className={classes.imageOverlayBlack} />
      </div>
      {isChampionshipDone && (
        <img
          className={classes.flagOverlay}
          src={require("assets/check-flag.png").default}
        />
      )}
      <Icon
        name="arrow-down"
        color="white"
        className={classes.backIcon}
        size={25}
        onClick={() => history.replace(routes.TOURNAMENT)}
      />
      {championship?.description && (
        <Icon
          className={classes.descriptionIcon}
          size={30}
          name="info"
          color="black"
          onClick={(e) => {
            e?.stopPropagation();
            showDescription(championship.description);
          }}
        />
      )}
      <Icon
        name="volume"
        color={mute ? "red" : "white"}
        className={cn(classes.volumeIcon, {
          [classes["volumeIcon--mute"]]: mute,
        })}
        size={35}
        onClick={() => setMute((v) => !v)}
      />
      <Icon
        name="edit"
        color="white"
        className={classes.editIcon}
        size={35}
        onClick={() => history.push(routes.ADMIN_CHAMPIONSHIP_FUNC(params?.id))}
      />
      <div className={classes.sideInfo}></div>
      <div className={classes.content}>
        {isChampionshipDone && (
          <>
            <img className={classes.ch1} src={champagne} />
            <img className={classes.ch2} src={champagne} />
          </>
        )}
        <div className={classes.top}>
          <div className={classes.laurelStats}>
            <h1 className={classes.mainTitle}>{championship?.name}</h1>
            <div
              style={{
                backgroundImage: `url(${
                  require("assets/laurel-avatar.png")?.default
                })`,
              }}
              className={cn(classes.statsAvatar, {
                [classes.statsAvatarWinner]:
                  isChampionshipDone && laurelWins >= reneWins,
                [classes.statsAvatarLoser]:
                  isChampionshipDone && reneWins > laurelWins,
              })}
            >
              {isChampionshipDone && laurelWins >= reneWins && (
                <img
                  className={classes.winnerCrown}
                  src={require("assets/circle-crown.png")?.default}
                />
              )}
            </div>
            <p>{laurelWins}</p>
          </div>
          <span>-</span>
          <div className={classes.reneStats}>
            <p>{reneWins}</p>
            <div
              style={{
                backgroundImage: `url(${
                  require("assets/rene-avatar.png")?.default
                })`,
              }}
              className={cn(classes.statsAvatar, {
                [classes.statsAvatarWinner]:
                  isChampionshipDone && reneWins >= laurelWins,
                [classes.statsAvatarLoser]:
                  isChampionshipDone && laurelWins > reneWins,
              })}
            >
              {isChampionshipDone && reneWins >= laurelWins && (
                <img
                  className={classes.winnerCrown}
                  src={require("assets/circle-crown.png")?.default}
                />
              )}
            </div>
          </div>
        </div>
        <div className={classes.topLabel}>
          <span>Laurel</span>
          <span>René</span>
        </div>
        <div className={classes.raceContainer}>
          {((mounted && !championship?.races) ||
            championship?.races?.length === 0) && (
            <p className={classes.noRacesText}>
              Ce championnat n&rsquo;a aucune course configurée
            </p>
          )}
          {championship?.races?.map((race, i) => {
            const {
              id: raceId,
              name: raceName,
              carsChosenByDriver,
              carLaurel,
              carLaurelNickname,
              carRene,
              carReneNickname,
              timeTrialLaurel,
              timeTrialRene,
              date,
              track,
              reverse,
              laps,
              winner,
              restrictions,
              tires,
              tireWear,
              weather,
              carType,
              maxPP,
              tuningAllowed,
              finishNotes,
              videoUrl,
            } = race || {};
            const isActive = i === activeRaceIndex;
            const isDone = i < activeRaceIndex || isChampionshipDone;
            const isFuture = i > activeRaceIndex && !isChampionshipDone;
            const trackData = tracks?.find((t) => t.name === track);
            const { location, flag, km } = trackData || {};
            const raceLengthKm =
              km && laps > 0 ? (km * laps || "").toFixed(3) : null;

            let carLaurelUrl = require("assets/mystery-car.png")?.default;
            if (carLaurel) {
              try {
                carLaurelUrl = require(`assets/cars/${carLaurel}.png`)?.default;
              } catch (e) {
                console.log("error", e);
              }
            }

            let carReneUrl = require("assets/mystery-car.png")?.default;
            if (carRene) {
              try {
                carReneUrl = require(`assets/cars/${carRene}.png`)?.default;
              } catch (e) {
                console.log("error", e);
              }
            }

            return (
              <div
                id={isActive ? "active-race" : ""}
                key={race?.id}
                className={cn(classes.race, {
                  [classes.raceDone]: isDone,
                  [classes.raceFuture]: isFuture,
                  [classes.raceActive]: isActive,
                  [classes.raceTimeTrial]: championship?.isTimeTrial,
                })}
              >
                <div className={classes.laurelContainer}>
                  <div
                    onClick={() =>
                      championship?.isTimeTrial
                        ? history.push({
                            pathname: routes.ADMIN_CHAMPIONSHIP_RACE_FUNC(
                              params?.id
                            ),
                            state: {
                              raceId: race?.id,
                              champId: params?.id,
                              redirect: history.location.pathname,
                            },
                          })
                        : openCarModal(carLaurel)
                    }
                    className={classes.carImage}
                  >
                    <img src={carLaurelUrl} />
                    <p
                      className={cn(classes.carName, {
                        [classes.rainbowTextAnimated]:
                          carsChosenByDriver && carLaurel,
                      })}
                    >
                      {carLaurel ||
                        (carsChosenByDriver ? "???" : "Non spécifié")}
                    </p>
                    {championship?.isTimeTrial && (
                      <span className={classes.carTimeTrialText}>
                        {race?.timeTrialLaurel || "00:00.000"}
                      </span>
                    )}
                    {winner === "René" && (
                      <div className={classes.loserOverlay} />
                    )}
                    {winner === "Laurel" && (
                      <div className={classes.winnerOverlay} />
                    )}
                    {carLaurelNickname && (
                      <div className={classes.carNicknameLaurel}>
                        {carLaurelNickname}
                      </div>
                    )}
                    {!carsChosenByDriver && (
                      <div className={classes.carChoiceLockedLaurel} />
                    )}
                  </div>
                  {winner === "Laurel" && (
                    <img
                      className={classes.winnerTrophy}
                      src={require("assets/trophy.png").default}
                    />
                  )}
                </div>
                <p className={classes.vs}>VS</p>
                {raceInfoContainerFct(race, i, false)}
                <div className={classes.reneContainer}>
                  {winner === "René" && (
                    <img
                      className={classes.winnerTrophy}
                      src={require("assets/trophy.png").default}
                    />
                  )}
                  <div
                    onClick={() =>
                      championship?.isTimeTrial
                        ? history.push({
                            pathname: routes.ADMIN_CHAMPIONSHIP_RACE_FUNC(
                              params?.id
                            ),
                            state: {
                              raceId: race?.id,
                              champId: params?.id,
                              redirect: history.location.pathname,
                            },
                          })
                        : openCarModal(carRene)
                    }
                    className={classes.carImage}
                  >
                    <img src={carReneUrl} />
                    <p
                      className={cn({
                        [classes.rainbowTextAnimated]:
                          carsChosenByDriver && carRene,
                      })}
                    >
                      {carRene || (carsChosenByDriver ? "???" : "Non spécifié")}
                    </p>
                    {championship?.isTimeTrial && (
                      <span className={classes.carTimeTrialText}>
                        {race?.timeTrialRene || "00:00.000"}
                      </span>
                    )}
                    {winner === "Laurel" && (
                      <div className={classes.loserOverlay} />
                    )}
                    {winner === "René" && (
                      <div className={classes.winnerOverlay} />
                    )}
                    {carReneNickname && (
                      <div className={classes.carNicknameRene}>
                        <b
                          style={{
                            transform: "scaleX(-1)",
                            display: "inline-block",
                          }}
                        >
                          {carReneNickname}
                        </b>
                      </div>
                    )}
                    {!carsChosenByDriver && (
                      <div className={classes.carChoiceLockedRene} />
                    )}
                  </div>
                </div>
                {raceInfoContainerFct(race, i, true)}
                {/* {winner && <img className={classes.winnerTrophy} style={winner === 'Laurel' ? { left: '30vw' } : { right: '30vw' }} src={require('assets/trophy.png').default} />} */}
              </div>
            );
          })}
        </div>
      </div>
      {isChampionshipDone && <Confetti width={width} height={height} />}
      {openCar && openCarUrl && (
        <div className={classes.openCarOverlay} onClick={() => closeCarModal()}>
          <img src={openCarUrl} />
          <p>{openCar}</p>
        </div>
      )}
      {/* {openTrack && (
        <div className={classes.openTrackOverlay} onClick={() => closeTrackModal()}>
          <img src={openTrack?.url} />
          <p>{openTrack?.name}</p>
        </div>
      )} */}
    </Layout>
  );
}
