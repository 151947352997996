import React from "react";
import {
  useEffect,
  useState,
  useHistory,
  useParams,
  useStyle,
} from "utils/hooks";
import {
  Navigation,
  Typo,
  Icon,
  Container,
  Row,
  Col,
  Input,
  InputSelect,
  Button,
} from "components";
import routes from "utils/routes";
import {
  tracks,
  tracksOptions,
  carsOptions,
  tiresOptions,
  participantsOptions,
} from "data";
import Layout from "components/Layout";
import { Helmet } from "react-helmet";
import styles from "./styles";
import "react-datepicker/dist/react-datepicker.css";

import firebase from "utils/firebase";
const db = firebase.database();

export default function Race(props) {
  const classes = useStyle(styles, props);
  const [openCar, setOpenCar] = useState(null);
  const [championship, setChampionship] = useState();
  const [race, setRace] = useState();
  const [dirty, setDirty] = useState(false);

  const history = useHistory();
  const raceId = history.location.state?.raceId;
  const redirectUrl = history.location.state?.redirect;
  const params = useParams();

  useEffect(() => {
    const ref = db.ref(`championships/${params?.id}`);

    ref.once("value", (snapshot) => {
      const snapshotVal = snapshot.val();
      setChampionship(snapshotVal);

      if (raceId) {
        const race = (snapshotVal?.races || []).find((r) => r.id === raceId);
        setRace(race);
      }
    });
  }, []);

  const setData = (e, checkbox = false) => {
    const name = e?.target?.name;
    let value = checkbox ? e?.target?.checked : e?.target?.value;
    setDirty(true);

    const data = {
      ...(race || {}),
      [name]: value,
    };

    if (name === "laps" && value) {
      if (value < 1) data[name] = 1;
      if (value > 99) data[name] = 99;
    }

    setRace(data);
  };

  const setCustomData = (name, value) => {
    setDirty(true);

    const data = {
      ...(race || {}),
      [name]: value,
    };

    setRace(data);
  };

  const setOptionsData = (name, value, multiple = false) => {
    let _value = null;
    if (value?.[0]?.value !== race?.[name]) {
      setDirty(true);
    }

    if (multiple) {
      _value = value?.map((v) => v?.value);
    } else {
      _value = value?.[0]?.value;
    }

    setRace({
      ...(race || {}),
      [name]: _value,
    });
  };

  const isValid = () => {
    return race?.track && (race?.laps || championship?.isTimeTrial);
  };

  const save = () => {
    const championshipsDbRef = db.ref(`championships/${params?.id}/races`);
    if (raceId) {
      const updatedRaces = [...(championship?.races || [])].map((r) => {
        if (r?.id === raceId) {
          const update = {
            ...race,
            id: raceId,
          };
          if (!update.laps) {
            update.laps = 1;
          }
          return update;
        } else {
          return r;
        }
      });
      championshipsDbRef.set(JSON.parse(JSON.stringify(updatedRaces)));
    } else {
      const newRaceId = championshipsDbRef.push()?.key;
      const raceData = {
        ...race,
        id: newRaceId,
      };
      championshipsDbRef.set([
        ...(championship?.races || []),
        JSON.parse(JSON.stringify(raceData)),
      ]);
    }

    history.replace(redirectUrl || routes.ADMIN_CHAMPIONSHIP_FUNC(params?.id));
  };

  const deleteRace = () => {
    if (!confirm("Es-tu certain de vouloir supprimer cette course?")) return;

    const championshipsDbRef = db.ref(`championships/${params?.id}/races`);
    const updatedRaces = [...(championship?.races || [])].filter((r) => {
      return r.id !== raceId;
    });
    championshipsDbRef.set(updatedRaces);

    history.replace(routes.ADMIN_CHAMPIONSHIP_FUNC(params?.id));
  };

  const goBack = () => {
    if (dirty) {
      if (
        !confirm(
          "Attention! Tout changement non sauvegardé sera perdu. Veux-tu vraiment quitter la page?"
        )
      )
        return;
    }

    history.replace(routes.ADMIN_CHAMPIONSHIP_FUNC(params?.id));
  };

  const openCarModal = (car) => {
    if (car) {
      setOpenCar(car);
    }
  };

  const closeCarModal = () => {
    setOpenCar(null);
  };

  const getDistanceLabel = (km) => {
    if (!km) return null;

    if (km < 7) return <span style={{ color: "green" }}>Court</span>;
    if (km < 13.5) return <span style={{ color: "orange" }}>Moyen</span>;
    if (km < 21) return <span style={{ color: "red" }}>Long</span>;
    if (km >= 21) return <span style={{ color: "purple" }}>Endurance</span>;
  };

  const selectedTrack = race?.track
    ? tracksOptions().find((t) => t.value === race.track)
    : null;
  const selectedTrackRaw = selectedTrack
    ? tracks?.find((t) => t.name === race?.track)
    : null;
  const selectedCarLaurel = race?.carLaurel
    ? carsOptions().find((t) => t.value === race.carLaurel)
    : null;
  const selectedCarRene = race?.carRene
    ? carsOptions().find((t) => t.value === race.carRene)
    : null;
  const selectedTires = race?.tires
    ? tiresOptions().find((t) => t.value === race.tires)
    : null;
  const selectedWinner = race?.winner
    ? participantsOptions().find((t) => t.value === race.winner)
    : null;

  // if (race.carLaurel) race.carLaurel = [carsOptions().find(c => c.value === race.carLaurel)];
  // if (race.carRene) race.carRene = [carsOptions().find(c => c.value === race.carRene)];

  const raceLengthKm =
    selectedTrackRaw && race?.laps > 0
      ? (selectedTrackRaw?.km * race?.laps || "").toFixed(3)
      : null;

  let carLaurelUrl = require("assets/mystery-car.png")?.default;
  if (selectedCarLaurel?.value) {
    try {
      carLaurelUrl =
        require(`assets/cars/${selectedCarLaurel?.value}.png`)?.default;
    } catch (e) {
      console.log("error", e);
    }
  }

  let carReneUrl = require("assets/mystery-car.png")?.default;
  if (selectedCarRene?.value) {
    try {
      carReneUrl =
        require(`assets/cars/${selectedCarRene?.value}.png`)?.default;
    } catch (e) {
      console.log("error", e);
    }
  }

  let openCarUrl = require("assets/mystery-car.png")?.default;
  if (openCar?.value) {
    try {
      openCarUrl = require(`assets/cars/${openCar?.value}.png`)?.default;
    } catch (e) {
      console.log("error", e);
    }
  }

  return (
    <Layout className={classes.base}>
      <Helmet>
        <title>Admin - {championship?.name || ""}</title>
      </Helmet>
      <Icon
        name="arrow-down"
        color="black"
        className={classes.backIcon}
        size={25}
        onClick={goBack}
      />
      <Container className={classes.body}>
        <h1>
          {championship?.name} - {raceId ? "Modifier la" : "Ajouter une"}{" "}
          {championship?.isTimeTrial ? "Course Time Trial" : "Course"}
        </h1>

        <h2>Détails</h2>
        <Input
          label={
            championship?.isTimeTrial
              ? "Nom du time trial (optionnel)"
              : "Nom de la course (optionnel)"
          }
          name="name"
          placeholder="Ex: Le Duel Ultime"
          value={race?.name}
          onChange={(e) => setData(e)}
        />

        <Input
          label="Météo"
          name="weather"
          value={race?.weather}
          placeholder="Ex: nuit avec pluie"
          onChange={(e) => setData(e)}
        />
        <div className={classes.inputRow}>
          <InputSelect
            label="Piste"
            className={classes.input}
            name="track"
            options={tracksOptions()}
            value={selectedTrack ? [selectedTrack] : []}
            onChange={(v) => setOptionsData("track", v)}
          />
          {!championship?.isTimeTrial && (
            <Input
              label="# tours"
              className={classes.input}
              name="laps"
              helperText={
                raceLengthKm ? (
                  <>
                    Distance totale: {raceLengthKm}km -{" "}
                    {getDistanceLabel(raceLengthKm)}
                  </>
                ) : null
              }
              value={race?.laps}
              pattern="[0-9]*"
              type="number"
              min={1}
              max={99}
              onChange={(e) => setData(e)}
            />
          )}
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="isReverse">Reverse?</label>
          <input
            name="reverse"
            id="isReverse"
            type="checkbox"
            onChange={(e) => setData(e, true)}
            checked={race?.reverse}
          />
        </div>
        <br />

        <h2>Voitures</h2>

        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="carsChosenByDriver">Au choix du pilote</label>
          <input
            name="carsChosenByDriver"
            id="carsChosenByDriver"
            type="checkbox"
            onChange={(e) => setData(e, true)}
            checked={race?.carsChosenByDriver}
          />
        </div>

        <div className={classes.carSelectContainer}>
          <InputSelect
            className={classes.carInput}
            label="Voiture Laurel 🚙"
            placeholder=""
            name="carLaurel"
            options={carsOptions()}
            value={selectedCarLaurel ? [selectedCarLaurel] : []}
            onChange={(v) => setOptionsData("carLaurel", v)}
          />
          <Input
            className={classes.nicknameInput}
            name="carLaurelNickname"
            placeholder="Surnom"
            value={race?.carLaurelNickname}
            onChange={(e) => setData(e)}
            style={{ fontSize: 10 }}
          />
          <img
            src={carLaurelUrl}
            onClick={() => openCarModal(selectedCarLaurel)}
          />
        </div>
        <div className={classes.carSelectContainer}>
          <InputSelect
            className={classes.carInput}
            label="Voiture Papa 🚗"
            placeholder=""
            name="carRene"
            options={carsOptions()}
            value={selectedCarRene ? [selectedCarRene] : []}
            onChange={(v) => setOptionsData("carRene", v)}
          />
          <Input
            className={classes.nicknameInput}
            name="carReneNickname"
            placeholder="Surnom"
            value={race?.carReneNickname}
            onChange={(e) => setData(e)}
            style={{ fontSize: 10 }}
          />
          <img src={carReneUrl} onClick={() => openCarModal(selectedCarRene)} />
        </div>
        <h2>Restrictions</h2>
        <Input
          label="Type de voiture"
          name="carType"
          placeholder="Ex: européennes, BMW, propulsion..."
          value={race?.carType}
          onChange={(e) => setData(e)}
        />
        <div className={classes.inputRow}>
          <InputSelect
            className={classes.input}
            label="Pneus"
            placeholder=""
            name="tires"
            options={tiresOptions()}
            value={selectedTires ? [selectedTires] : []}
            onChange={(v) => setOptionsData("tires", v)}
          />
          <Input
            className={classes.input}
            label="Usure de pneus"
            name="tireWear"
            type="number"
            min={0}
            max={50}
            value={race?.tireWear}
            onChange={(e) => setData(e)}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="tuningAllowed">Modifications Autorisées?</label>
          <input
            name="tuningAllowed"
            id="tuningAllowed"
            type="checkbox"
            onChange={(e) => setData(e, true)}
            checked={race?.tuningAllowed}
          />
        </div>
        <br />
        {race?.tuningAllowed && (
          <Input
            label="Maximum PP"
            name="maxPP"
            placeholder="Ex: 500"
            type="number"
            min={0}
            max={1000}
            value={race?.maxPP}
            onChange={(e) => setData(e)}
          />
        )}
        <Input
          label="Autres restrictions"
          name="restrictions"
          placeholder="Ex: max 500hp..."
          value={race?.restrictions}
          area
          style={{ width: "100%" }}
          onChange={(e) => setData(e)}
        />

        {championship?.isTimeTrial && (
          <>
            <h2 style={{ marginTop: "35px" }}>Temps du Time Trial</h2>
            <Input
              label="Temps de tour Laurel"
              name="timeTrialLaurel"
              placeholder="00:00.000"
              value={race?.timeTrialLaurel}
              onChange={(e) => setData(e)}
            />
            <Input
              label="Temps de tour Papa"
              name="timeTrialRene"
              placeholder="00:00.000"
              value={race?.timeTrialRene}
              onChange={(e) => setData(e)}
            />
          </>
        )}

        <h2 style={{ marginTop: "35px" }}>Complétion</h2>
        <InputSelect
          label="Gagnant 🏁"
          name="winner"
          options={participantsOptions()}
          value={selectedWinner ? [selectedWinner] : []}
          onChange={(v) => setOptionsData("winner", v)}
        />
        <Input
          label="Notes"
          name="finishNotes"
          placeholder="Ex: fin spectaculaire, 0.002s de différence..."
          value={race?.finishNotes}
          area
          style={{ width: "100%" }}
          onChange={(e) => setData(e)}
        />
        <Input
          label="Video url 🎥"
          name="videoUrl"
          placeholder="Ex: https://youtube.com/12345"
          value={race?.videoUrl}
          onChange={(e) => setData(e)}
        />
        <br />

        {raceId && (
          <Button onClick={deleteRace} intent="error">
            Supprimer
          </Button>
        )}
        <Button disabled={!isValid()} onClick={save}>
          {raceId
            ? "Sauvegarder"
            : championship?.isTimeTrial
            ? "Ajouter le Time Trial"
            : "Ajouter la course"}
        </Button>
      </Container>
      {openCar && openCarUrl && (
        <div className={classes.openCarOverlay} onClick={() => closeCarModal()}>
          <img src={openCarUrl} />
          <p>{openCar?.label}</p>
        </div>
      )}
    </Layout>
  );
}
